import ACTION, {
  LOSTCARD_STATE,
  GET_LANGUAGE_USED_DATA_STATE,
  GET_OFFICER_NAME_DATA_STATE,
  GET_REASONS_DATA_STATE,
  FORM_SAVE_STATE,
  GET_RACE_DATA_STATE,
  UPLOAD_FILE_STATE,
  GET_NATIONALITY_DATA_STATE,
} from './creator'

const init = {
  status: LOSTCARD_STATE.PENDING,
  message: '',
  lostCardData: [],
}
const init_languageUsed = {
  status: GET_LANGUAGE_USED_DATA_STATE.PENDING,
  message: '',
  data: [],
}
const init_officerName = {
  status: GET_OFFICER_NAME_DATA_STATE.PENDING,
  message: '',
  data: [],
}
const init_reasons = {
  status: GET_REASONS_DATA_STATE.PENDING,
  message: '',
  data: [],
}
const init_race = {
  status: GET_RACE_DATA_STATE.PENDING,
  message: '',
  data: [],
}
const init_nationality = {
  status: GET_NATIONALITY_DATA_STATE.PENDING,
  message: '',
  data: [],
}
const init_update = {
  fullName: '',
  icNum: '',
  file: '',
  age: '',
  fileName: '',
  contactNum: '',
  reason: [],
  race: [],
  gender: '',
  nationality: [],
  birthOfDate: '',
  employersCompany: '',
  workingCompany: '',
  residentialAddress: '',
  companyAddress: '',
  officeNum: '',
  station: '',
  reportNum: '',
  dateOfReport: '',
  timeOfReport: '',
  languageUsed: '',
  officer: '',
  officerIcNum: '',
  policeId: '',
  translatorName: '',
  passportNum: '',
  originLanguage: '',
  reasons: '',
  complainantStatement: '',
  formIdResponse: '',
}
const init_next = {
  status: FORM_SAVE_STATE.PENDING,
  message: '',
}
const init_file = {
  status: UPLOAD_FILE_STATE.PENDING,
  message: '',
}
const init_appFormValidation = {
  status: '',
  message: '',
  data: {
    fullNameValid: false,
    icNumValid: false,
    fileValid: false,
    genderValid: false,
    raceValid: false,
    nationalityValid: false,
    birthOfDateValid: null,
    ageValid: false,
    employersCompanyValid: false,
    contactNumValid: false,
    residentialAddressValid: false,
    workingCompanyValid: false,
    companyAddressValid: false,
    officeNumValid: false,
    stationValid: false,
    reportNumValid: false,
    dateOfReportValid: null,
    timeOfReportValid: null,
    languageUsedValid: false,
    officerValid: false,
    officerIcNumValid: false,
    policeIdValid: false,
    translatorNameValid: false,
    passportNumValid: false,
    originLanguageValid: false,
    reasonsValid: false,
    complainantStatementValid: false,
  },
}

export const userLostCardTableData = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.LOSTCARD.PENDING:
    case ACTION.LOSTCARD.SUCCESS:
    case ACTION.LOSTCARD.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
export const getLanguageUsedData = (state = init_languageUsed, { type, payload }) => {
  switch (type) {
    case ACTION.GET_LANGUAGE_USED_DATA.PENDING:
    case ACTION.GET_LANGUAGE_USED_DATA.SUCCESS:
      return {
        ...state,
        status: payload.status,
        message: payload.message,
        data: payload.data,
        //init_update: { ...init_update, languageUsed: payload.data },
      }
    case ACTION.GET_LANGUAGE_USED_DATA.FAILURE:
      return { ...state, ...getLanguageUsedData }
    default:
      return state
  }
}
export const getOfficerNameData = (state = init_officerName, { type, payload }) => {
  switch (type) {
    case ACTION.GET_OFFICER_NAME_DATA.PENDING:
    case ACTION.GET_OFFICER_NAME_DATA.SUCCESS:
      return {
        ...state,
        status: payload.status,
        message: payload.message,
        data: payload.data,
        // init_update: { ...init_update, reasons: payload.data },
      }
    case ACTION.GET_OFFICER_NAME_DATA.FAILURE:
      return { ...state, ...getOfficerNameData }
    default:
      return state
  }
}
export const getReasonsData = (state = init_reasons, { type, payload }) => {
  switch (type) {
    case ACTION.GET_REASONS_DATA.PENDING:
    case ACTION.GET_REASONS_DATA.SUCCESS:
      return {
        ...state,
        status: payload.status,
        message: payload.message,
        data: payload.data,
        // init_update: { ...init_update, reasons: payload.data },
      }
    case ACTION.GET_REASONS_DATA.FAILURE:
      return { ...state, ...getReasonsData }
    default:
      return state
  }
}
export const getRaceData = (state = init_race, { type, payload }) => {
  switch (type) {
    case ACTION.GET_RACE_DATA.PENDING:
    case ACTION.GET_RACE_DATA.SUCCESS:
      return {
        ...state,
        status: payload.status,
        message: payload.message,
        data: payload.data,
        init_update: { ...init_update, race: payload.data },
      }
    case ACTION.GET_RACE_DATA.FAILURE:
      return { ...state, ...getRaceData }
    default:
      return state
  }
}
export const getNationalityData = (state = init_nationality, { type, payload }) => {
  switch (type) {
    case ACTION.GET_NATIONALITY_DATA.PENDING:
    case ACTION.GET_NATIONALITY_DATA.SUCCESS:
      return {
        ...state,
        status: payload.status,
        message: payload.message,
        data: payload.data,
        init_update: { ...init_update, nationality: payload.data },
      }
    case ACTION.GET_NATIONALITY_DATA.FAILURE:
      return { ...state, ...getRaceData }
    default:
      return state
  }
}
export const updateLostCardFormData = (state = init_update, { type, payload }) => {
  switch (type) {
    case ACTION.UPDATE_FORM_DATA.SUCCESS:
      return { ...state, ...payload }
    case ACTION.UPDATE_FORM_DATA.CLEAR:
      return init_update
    default:
      return state
  }
}
export const nextAddNewReportData = (state = init_next, { type, payload }) => {
  switch (type) {
    case ACTION.FORM_SAVE.PENDING:
    case ACTION.FORM_SAVE.SUCCESS:
    case ACTION.FORM_SAVE.FAILURE:
    case ACTION.FORM_GETID.SUCCESS:
      return { ...state, ...payload }
    default:
      return state
  }
}
export const uploadFile = (state = init_file, { type, payload }) => {
  switch (type) {
    case ACTION.UPLOAD_FILE.PENDING:
    case ACTION.UPLOAD_FILE.SUCCESS:
    case ACTION.UPLOAD_FILE.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
export const lostCardNewReportFormValidation = (
  state = init_appFormValidation,
  { type, payload },
) => {
  switch (type) {
    case ACTION.APP_FORM_VALIDATION.SUCCESS:
      return { ...state, data: { ...state.data, ...payload } }
    case ACTION.APP_FORM_VALIDATION.CLEAR:
      return init_appFormValidation
    default:
      return state
  }
}
