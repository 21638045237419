const AUX_PAYMENTCART_ACTIONS = {
  AUX_CASH_PAYMENT: {
    PENDING: 'AUX_CASH_PAYMENT_PENDING',
    SUCCESS: 'AUX_CASH_PAYMENT_SUCCESS',
    FAILURE: 'AUX_CASH_PAYMENT_FAILURE',
    UPDATE: 'AUX_CASH_PAYMENT_UPDATE',
  },
}

export const AUX_CASH_PAYMENT_STATE = {
  REFRESHED: 'AUX_CASH_PAYMENT_REFRESHED',
  IN_PROGRESS: 'AUX_CASH_PAYMENT_PROGRESS',
  SUCCESS: 'AUX_CASH_PAYMENT_RETRIEVED',
  ERROR: 'AUX_CASH_PAYMENT_ERROR',
  PENDING: 'AUX_CASH_PAYMENT_PENDING',
}

export default AUX_PAYMENTCART_ACTIONS
