import ACTION, {
  VEHICLEPASS_DATA_STATE,
  VEHICLEPASS_VERIFY_STATE,
  VEHICLEPASS_REJECT_STATE,
} from './creator'

const init = {
  status: VEHICLEPASS_DATA_STATE.PENDING,
  message: '',
  dataForm: {
    platNo: '',
    vehicleType: '',
    roadTaxExpired: '',
    drivingLicense: '',
    roadTax: '',
    paymentMethod: '',
  },
}
const init_verify = {
  status: VEHICLEPASS_VERIFY_STATE.PENDING,
  message: '',
}
const init_reject = {
  status: VEHICLEPASS_REJECT_STATE.PENDING,
  message: '',
}

export const tenantVehiclePassDataList = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.VEHICLEPASS_DATA.PENDING:
    case ACTION.VEHICLEPASS_DATA.SUCCESS:
    case ACTION.VEHICLEPASS_DATA.FAILURE:
      return { ...state, ...payload }
    case ACTION.VEHICLEPASS_DATA.UPDATE:
      let _state = { ...state }
      _state.dataForm = { ...state.dataForm, ...payload }
      return _state
    default:
      return state
  }
}

export const vpVerifyApplication = (state = init_verify, { type, payload }) => {
  switch (type) {
    case ACTION.VERIFY.PENDING:
    case ACTION.VERIFY.SUCCESS:
    case ACTION.VERIFY.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const vpRejectApplication = (state = init_reject, { type, payload }) => {
  switch (type) {
    case ACTION.REJECT.PENDING:
    case ACTION.REJECT.SUCCESS:
    case ACTION.REJECT.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
