import ACTION, { EMAIL_SUBMIT_STATE } from './creator'

const init_submit = {
  status: EMAIL_SUBMIT_STATE.PENDING,
  message: '',
}

export const SubmitForgotPasswordData = (state = init_submit, { type, payload }) => {
  switch (type) {
    case ACTION.EMAIL_SUBMIT.PENDING:
    case ACTION.EMAIL_SUBMIT.SUCCESS:
    case ACTION.EMAIL_SUBMIT.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}

export default SubmitForgotPasswordData
