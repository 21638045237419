const SAFETYSECURITY_LIST_ACTIONS = {
  SAFETYSECURITY_LIST: {
    PENDING: 'SAFETYSECURITYS_LIST_PENDING',
    SUCCESS: 'SAFETYSECURITY_LIST_SUCCESS',
    FAILURE: 'SAFETYSECURITY_LIST_FAILURE',
  },
}

export const SAFETYSECURITY_LIST_STATE = {
  REFRESHED: 'SAFETYSECURITY_LIST_REFRESHED',
  IN_PROGRESS: 'SAFETYSECURITY_LIST_IN_PROGRESS',
  SUCCESS: 'SAFETYSECURITY_LIST_RETRIEVED',
  ERROR: 'SAFETYSECURITY_LIST_ERROR',
  PENDING: 'SAFETYSECURITY_LIST_PENDING',
}

export default SAFETYSECURITY_LIST_ACTIONS
