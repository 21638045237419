const PROFILE_ACTIONS = {
  PROFILE: {
    PENDING: 'PROFILE_PENDING',
    SUCCESS: 'PROFILE_SUCCESS',
    FAILURE: 'PROFILE_FAILURE',
    UPDATE: 'PROFILE_UPDATE',
  },
  PHOTO: {
    PENDING: 'PHOTO_PENDING',
    SUCCESS: 'PHOTO_SUCCESS',
    FAILURE: 'PHOTO_FAILURE',
  },
  CHANGE_PASSWORD: {
    PENDING: 'CHANGE_PASSWORD_PENDING',
    SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    FAILURE: 'CHANGE_PASSWORD_FAILURE',
    UPDATE: 'CHANGE_PASSWORD_UPDATE',
  },
  CHANGE_CONTACT_NUMBER: {
    PENDING: 'CHANGE_CONTACT_NUMBER_PENDING',
    SUCCESS: 'CHANGE_CONTACT_NUMBER_SUCCESS',
    FAILURE: 'CHANGE_CONTACT_NUMBER_FAILURE',
    UPDATE: 'CHANGE_CONTACT_NUMBER_UPDATE',
  },
  CHANGE_EMAIL: {
    PENDING: 'CHANGE_EMAIL_PENDING',
    SUCCESS: 'CHANGE_EMAIL_SUCCESS',
    FAILURE: 'CHANGE_EMAIL_FAILURE',
    UPDATE: 'CHANGE_EMAIL_UPDATE',
  },
  CHANGE_CONTACT_NUMBER_COMPANY: {
    PENDING: 'CHANGE_CONTACT_NUMBER_COMPANY_PENDING',
    SUCCESS: 'CHANGE_CONTACT_NUMBER_COMPANY_SUCCESS',
    FAILURE: 'CHANGE_CONTACT_NUMBER_COMPANY_FAILURE',
    UPDATE: 'CHANGE_CONTACT_NUMBER_COMPANY_UPDATE',
  },
  CHANGE_EMAIL_COMPANY: {
    PENDING: 'CHANGE_EMAIL_COMPANY_PENDING',
    SUCCESS: 'CHANGE_EMAIL_COMPANY_SUCCESS',
    FAILURE: 'CHANGE_EMAIL_COMPANY_FAILURE',
    UPDATE: 'CHANGE_EMAIL_COMPANY_UPDATE',
  },
  CHANGE_ADDRESS_COMPANY: {
    PENDING: 'CHANGE_ADDRESS_COMPANY_PENDING',
    SUCCESS: 'CHANGE_ADDRESS_COMPANY_SUCCESS',
    FAILURE: 'CHANGE_ADDRESS_COMPANY_FAILURE',
    UPDATE: 'CHANGE_ADDRESS_COMPANY_UPDATE',
  },
}

export const PROFILE_STATE = {
  REFRESHED: 'PROFILE_REFRESHED',
  IN_PROGRESS: 'PROFILE_PROGRESS',
  SUCCESS: 'PROFILE_RETRIEVED',
  ERROR: 'PROFILE_ERROR',
}

export const CHANGE_PASSWORD_STATE = {
  REFRESHED: 'CHANGE_PASSWORD_REFRESHED',
  IN_PROGRESS: 'CHANGE_PASSWORD_PROGRESS',
  SUCCESS: 'CHANGE_PASSWORD_RETRIEVED',
  ERROR: 'CHANGE_PASSWORD_ERROR',
}

export const CHANGE_CONTACT_NUMBER_STATE = {
  REFRESHED: 'CHANGE_CONTACT_NUMBER_REFRESHED',
  IN_PROGRESS: 'CHANGE_CONTACT_NUMBER_PROGRESS',
  SUCCESS: 'CHANGE_CONTACT_NUMBER_RETRIEVED',
  ERROR: 'CHANGE_CONTACT_NUMBER_ERROR',
}

export const CHANGE_CONTACT_NUMBER_COMPANY_STATE = {
  REFRESHED: 'CHANGE_CONTACT_NUMBER_COMPANY_REFRESHED',
  IN_PROGRESS: 'CHANGE_CONTACT_NUMBER_COMPANY_PROGRESS',
  SUCCESS: 'CHANGE_CONTACT_NUMBER_COMPANY_RETRIEVED',
  ERROR: 'CHANGE_CONTACT_NUMBER_COMPANY_ERROR',
}

export const CHANGE_EMAIL_STATE = {
  REFRESHED: 'CHANGE_EMAIL_REFRESHED',
  IN_PROGRESS: 'CHANGE_EMAIL_PROGRESS',
  SUCCESS: 'CHANGE_EMAIL_RETRIEVED',
  ERROR: 'CHANGE_EMAIL_ERROR',
}

export const CHANGE_EMAIL_COMPANY_STATE = {
  REFRESHED: 'CHANGE_EMAIL_COMPANY_REFRESHED',
  IN_PROGRESS: 'CHANGE_EMAIL_COMPANY_PROGRESS',
  SUCCESS: 'CHANGE_EMAIL_COMPANY_RETRIEVED',
  ERROR: 'CHANGE_EMAIL_COMPANY_ERROR',
}

export const CHANGE_ADDRESS_COMPANY_STATE = {
  REFRESHED: 'CHANGE_ADDRESS_COMPANY_REFRESHED',
  IN_PROGRESS: 'CHANGE_ADDRESS_COMPANY_PROGRESS',
  SUCCESS: 'CHANGE_ADDRESS_COMPANY_RETRIEVED',
  ERROR: 'CHANGE_ADDRESS_COMPANY_ERROR',
}

export default PROFILE_ACTIONS
