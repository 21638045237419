const USER_PROFILE_ACTIONS = {
  USER_PROFILE: {
    PENDING: 'USER_PROFILE_PENDING',
    SUCCESS: 'USER_PROFILE_SUCCESS',
    FAILURE: 'USER_PROFILE_FAILURE',
    UPDATE: 'USER_PROFILE_UPDATE',
  },
  COUNTRY: {
    PENDING: 'COUNTRY_PENDING',
    SUCCESS: 'COUNTRY_SUCCESS',
    FAILURE: 'COUNTRY_FAILURE',
    UPDATE: 'COUNTRY_UPDATE',
  },
  USER_COMPANY: {
    PENDING: 'USER_COMPANY_PENDING',
    SUCCESS: 'USER_COMPANY_SUCCESS',
    FAILURE: 'USER_COMPANY_FAILURE',
    UPDATE: 'USER_COMPANY_UPDATE',
  },
  COMPANY_PROFILE: {
    PENDING: 'COMPANY_PROFILE_PENDING',
    SUCCESS: 'COMPANY_PROFILE_SUCCESS',
    FAILURE: 'COMPANY_PROFILE_FAILURE',
    UPDATE: 'COMPANY_PROFILE_UPDATE',
  },
}

export const USER_PROFILE_STATE = {
  PENDING: 'USER_PROFILE_PENDING',
  REFRESHED: 'USER_PROFILE_REFRESHED',
  IN_PROGRESS: 'USER_PROFILE_PROGRESS',
  SUCCESS: 'USER_PROFILE_RETRIEVED',
  ERROR: 'USER_PROFILE_ERROR',
}

export const COUNTRY_STATE = {
  PENDING: 'COUNTRY_PENDING',
  REFRESHED: 'COUNTRY_REFRESHED',
  SUCCESS: 'COUNTRY_SUCCESS',
  FAILURE: 'COUNTRY_FAILURE',
  UPDATE: 'COUNTRY_UPDATE',
}

export const USER_COMPANY_STATE = {
  PENDING: 'USER_COMPANY_PENDING',
  REFRESHED: 'USER_COMPANY_REFRESHED',
  SUCCESS: 'USER_COMPANY_SUCCESS',
  FAILURE: 'USER_COMPANY_FAILURE',
  UPDATE: 'USER_COMPANY_UPDATE',
}

export const COMPANY_PROFILE_STATE = {
  PENDING: 'COMPANY_PROFILE_PENDING',
  REFRESHED: 'COMPANY_PROFILE_REFRESHED',
  SUCCESS: 'COMPANY_PROFILE_SUCCESS',
  FAILURE: 'COMPANY_PROFILE_FAILURE',
  UPDATE: 'COMPANY_PROFILE_UPDATE',
}

export default USER_PROFILE_ACTIONS
