import ACTION, {
  COUNTRY_STATE,
  USER_COMPANY_STATE,
  USER_PROFILE_STATE,
  COMPANY_PROFILE_STATE,
} from './creator'

const init = {
  payload: {},
  status: USER_PROFILE_STATE.PENDING,
  message: '',
}

const init_company_profile = {
  payload: {},
  status: COMPANY_PROFILE_STATE.PENDING,
  message: '',
}

const init_country = {
  payload: {},
  status: COUNTRY_STATE.PENDING,
  message: '',
}

const init_user_company = {
  payload: {},
  status: USER_COMPANY_STATE.PENDING,
  message: '',
}

export const userProfileData = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.USER_PROFILE.PENDING:
    case ACTION.USER_PROFILE.SUCCESS:
    case ACTION.USER_PROFILE.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const companyProfileData = (state = init_company_profile, { type, payload }) => {
  switch (type) {
    case ACTION.COMPANY_PROFILE.PENDING:
    case ACTION.COMPANY_PROFILE.SUCCESS:
    case ACTION.COMPANY_PROFILE.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const countryDataList = (state = init_country, { type, payload }) => {
  switch (type) {
    case ACTION.COUNTRY.PENDING:
    case ACTION.COUNTRY.SUCCESS:
    case ACTION.COUNTRY.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const userCompany = (state = init_user_company, { type, payload }) => {
  switch (type) {
    case ACTION.USER_COMPANY.PENDING:
    case ACTION.USER_COMPANY.SUCCESS:
    case ACTION.USER_COMPANY.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
