import ACTION, { SUMMARY_VEHICLEPASS_STATE, FORM_SUBMIT_STATE } from './creator'

const init = {
  status: SUMMARY_VEHICLEPASS_STATE.PENDING,
  message: '',
  summaryData: {},
}

const init_submit = {
  status: FORM_SUBMIT_STATE.PENDING,
  message: '',
}

export const userRenewVehiclePassSummaryData = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.SUMMARY_VEHICLEPASS.PENDING:
    case ACTION.SUMMARY_VEHICLEPASS.SUCCESS:
    case ACTION.SUMMARY_VEHICLEPASS.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const submitRenewVehiclePassForm = (state = init_submit, { type, payload }) => {
  switch (type) {
    case ACTION.FORM_SUBMIT.PENDING:
    case ACTION.FORM_SUBMIT.SUCCESS:
    case ACTION.FORM_SUBMIT.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
