const LOSTCARD_ACTIONS = {
  LOSTCARD: {
    PENDING: 'LOSTCARD_PENDING',
    SUCCESS: 'LOSTCARD_SUCCESS',
    FAILURE: 'LOSTCARD_FAILURE',
  },
  GET_LANGUAGE_USED_DATA: {
    PENDING: 'GET_LANGUAGE_USED_DATA_PENDING',
    SUCCESS: 'GET_LANGUAGE_USED_DATA_SUCCESS',
    FAILURE: 'GET_LANGUAGE_USED_DATA_FAILURE',
    UPDATE: 'GET_LANGUAGE_USED_DATA_UPDATE',
  },
  GET_OFFICER_NAME_DATA: {
    PENDING: 'GET_OFFICER_NAME_DATA_PENDING',
    SUCCESS: 'GET_OFFICER_NAME_DATA_SUCCESS',
    FAILURE: 'GET_OFFICER_NAME_DATA_FAILURE',
    UPDATE: 'GET_OFFICER_NAME_DATA_UPDATE',
  },
  GET_REASONS_DATA: {
    PENDING: 'GET_REASONS_DATA_PENDING',
    SUCCESS: 'GET_REASONS_DATA_SUCCESS',
    FAILURE: 'GET_REASONS_DATA_FAILURE',
    UPDATE: 'GET_REASONS_DATA_UPDATE',
  },
  UPDATE_FORM_DATA: {
    PENDING: 'UPDATE_FORM_DATA_PENDING',
    SUCCESS: 'UPDATE_FORM_DATA_SUCCESS',
    FAILURE: 'UPDATE_FORM_DATA_FAILURE',
    UPDATE: 'UPDATE_FORM_DATA_UPDATE',
    CLEAR: 'UPDATE_FORM_DATA_CLEAR',
  },
  FORM_GETID: {
    PENDING: 'FORM_GETID_PENDING',
    SUCCESS: 'FORM_GETID_SUCCESS',
    FAILURE: 'FORM_GETID_FAILURE',
    UPDATE: 'FORM_GETID_UPDATE',
  },
  FORM_SAVE: {
    PENDING: 'FORM_SAVE_PENDING',
    SUCCESS: 'FORM_SAVE_SUCCESS',
    FAILURE: 'FORM_SAVE_FAILURE',
    UPDATE: 'FORM_SAVE_UPDATE',
  },
  GET_RACE_DATA: {
    PENDING: 'GET_RACE_DATA_PENDING',
    SUCCESS: 'GET_RACE_DATA_SUCCESS',
    FAILURE: 'GET_RACE_DATA_FAILURE',
    UPDATE: 'GET_RACE_DATA_UPDATE',
  },
  GET_NATIONALITY_DATA: {
    PENDING: 'GET_NATIONALITY_DATA_PENDING',
    SUCCESS: 'GET_NATIONALITY_DATA_SUCCESS',
    FAILURE: 'GET_NATIONALITY_DATA_FAILURE',
    UPDATE: 'GET_NATIONALITY_DATA_UPDATE',
  },
  UPLOAD_FILE: {
    PENDING: 'UPLOAD_FILE_PENDING',
    SUCCESS: 'UPLOAD_FILE_SUCCESS',
    FAILURE: 'UPLOAD_FILE_FAILURE',
    UPDATE: 'UPLOAD_FILE_UPDATE',
  },
  APP_FORM_VALIDATION: {
    PENDING: 'APP_FORM_VALIDATION_PENDING',
    SUCCESS: 'APP_FORM_VALIDATION_SUCCESS',
    FAILURE: 'APP_FORM_VALIDATION_FAILURE',
    UPDATE: 'APP_FORM_VALIDATION_UPDATE',
    CLEAR: 'APP_FORM_VALIDATION_CLEAR',
  },
}

export const LOSTCARD_STATE = {
  REFRESHED: 'LOSTCARD_REFRESHED',
  IN_PROGRESS: 'LOSTCARD_IN_PROGRESS',
  SUCCESS: 'LOSTCARD_RETRIEVED',
  ERROR: 'LOSTCARD_ERROR',
  PENDING: 'LOSTCARD_PENDING',
}
export const GET_LANGUAGE_USED_DATA_STATE = {
  REFRESHED: 'GET_LANGUAGE_USED_DATA_REFRESHED',
  IN_PROGRESS: 'GET_LANGUAGE_USED_DATA_PROGRESS',
  SUCCESS: 'GET_LANGUAGE_USED_DATA_RETRIEVED',
  ERROR: 'GET_LANGUAGE_USED_DATA_ERROR',
  PENDING: 'GET_LANGUAGE_USED_DATA_PENDING',
}
export const GET_OFFICER_NAME_DATA_STATE = {
  REFRESHED: 'GET_OFFICER_NAME_DATA_REFRESHED',
  IN_PROGRESS: 'GET_OFFICER_NAME_DATA_PROGRESS',
  SUCCESS: 'GET_OFFICER_NAME_DATA_RETRIEVED',
  ERROR: 'GET_OFFICER_NAME_DATA_ERROR',
  PENDING: 'GET_OFFICER_NAME_DATA_PENDING',
}
export const GET_REASONS_DATA_STATE = {
  REFRESHED: 'GET_REASONS_DATA_REFRESHED',
  IN_PROGRESS: 'GET_REASONS_DATA_PROGRESS',
  SUCCESS: 'GET_REASONS_DATA_RETRIEVED',
  ERROR: 'GET_REASONS_DATA_ERROR',
  PENDING: 'GET_REASONS_DATA_PENDING',
}
export const FORM_GETID_STATE = {
  REFRESHED: 'FORM_GETID_REFRESHED',
  IN_PROGRESS: 'FORM_GETID_PROGRESS',
  SUCCESS: 'FORM_GETID_RETRIEVED',
  ERROR: 'FORM_GETID_ERROR',
  PENDING: 'FORM_GETID_PENDING',
}
export const FORM_SAVE_STATE = {
  REFRESHED: 'FORM_SAVE_REFRESHED',
  IN_PROGRESS: 'FORM_SAVE_PROGRESS',
  SUCCESS: 'FORM_SAVE_RETRIEVED',
  ERROR: 'FORM_SAVE_ERROR',
  PENDING: 'FORM_SAVE_PENDING',
}
export const GET_RACE_DATA_STATE = {
  REFRESHED: 'GET_RACE_DATA_REFRESHED',
  IN_PROGRESS: 'GET_RACE_DATA_PROGRESS',
  SUCCESS: 'GET_RACE_DATA_RETRIEVED',
  ERROR: 'GET_RACE_DATA_ERROR',
  PENDING: 'GET_RACE_DATA_PENDING',
}
export const GET_NATIONALITY_DATA_STATE = {
  REFRESHED: 'GET_NATIONALITY_DATA_REFRESHED',
  IN_PROGRESS: 'GET_NATIONALITY_DATA_PROGRESS',
  SUCCESS: 'GET_NATIONALITY_DATA_RETRIEVED',
  ERROR: 'GET_NATIONALITY_DATA_ERROR',
  PENDING: 'GET_NATIONALITY_DATA_PENDING',
}
export const UPLOAD_FILE_STATE = {
  REFRESHED: 'UPLOAD_FILE_REFRESHED',
  IN_PROGRESS: 'UPLOAD_FILE_PROGRESS',
  SUCCESS: 'UPLOAD_FILE_RETRIEVED',
  ERROR: 'UPLOAD_FILE_ERROR',
  PENDING: 'UPLOAD_FILE_PENDING',
}

export default LOSTCARD_ACTIONS
