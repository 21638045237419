import ACTION, { FORM_SAVE_RENEW_STATE } from './creator'

const init_save = {
  status: FORM_SAVE_RENEW_STATE.PENDING,
  message: '',
}
const init_update = {
  noPlat: '',
  vehicleType: [],
  roadTaxExpired: '',
  licenseExpired: '',
  paymentMethod: [
    {
      id: '2',
      value: 'Cash',
      label: 'Cash / Tunai',
      checked: true,
      disabled: false,
    },
    {
      id: '3',
      value: 'Online',
      label: 'Online / Atas Talian',
      checked: false,
      disabled: true,
    },
    {
      id: '1',
      value: 'Company',
      label: 'Company / Syarikat',
      checked: false,
      disabled: true,
    },
  ],
  roadTax: '',
  drivingLicense: '',
  additionalDrivingLicense: '',
  formIdResponse: '',
  nextPage: false,
}

const renewRegistrationBodyVehiclePassData = (state = init_save, { type, payload }) => {
  switch (type) {
    case ACTION.FORM_SAVE_RENEW.SUCCESS:
    case ACTION.FORM_SAVE_RENEW.FAILURE:
    case ACTION.FORM_GETID.SUCCESS:
      return { ...state, ...payload }
    default:
      return state
  }
}
const updateRenewBodyVehicleFormData = (state = init_update, { type, payload }) => {
  switch (type) {
    case ACTION.UPDATE_FORM_DATA_RENEW.SUCCESS:
      return { ...state, ...payload }
    case ACTION.UPDATE_FORM_DATA_RENEW.CLEAR:
      return init_update
    default:
      return state
  }
}

export { renewRegistrationBodyVehiclePassData, updateRenewBodyVehicleFormData }
