import ACTION, { LOSTCARD_STATE, UPDATE_LOSTCARD_STATE, DELETE_LOSTCARD_STATE } from './creator'
const init = {
  status: LOSTCARD_STATE.PENDING,
  message: '',
  lostCardData: [],
}

export const auxPoliceLostCardTableData = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.LOSTCARD.PENDING:
    case ACTION.LOSTCARD.SUCCESS:
    case ACTION.LOSTCARD.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}

// MOCK DATA
// Create a new file, e.g., tableReducer.js

// Initial state for the table
const initialState = {
  tableData: [],
  updateStatus: null, // To store the status of the update action
}

// Reducer function
export const tableReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LOSTCARD_STATE.SUCCESS:
      return {
        ...state,
        tableData: action.payload,
        updateStatus: 'success',
      }
    // Add other cases as needed for different actions

    default:
      return state
  }
}

const initialDeleteState = {
  lostCardData: [],
  status: '',
  // ... other initial state properties
}

// Reducer function
export const lostCardReducer = (state = initialDeleteState, action) => {
  switch (action.type) {
    // ... other cases

    // Handle the delete action
    case DELETE_LOSTCARD_STATE.SUCCESS:
      return {
        ...state,
        lostCardData: action.payload,
        status: 'DELETE_SUCCESS', // Update the status if needed
      }

    // ... other cases

    default:
      return state
  }
}
