import ACTION, { BODYPASS_LIST_STATE } from './creator'

const init = {
  status: BODYPASS_LIST_STATE.PENDING,
  message: '',
  tableList: {
    list: [],
    pagination: {
      page: 1,
      itemPerPage: 10,
      count: 0,
      totalCount: 0,
    },
  },
}

export const tenantBodyPassTableDataList = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.BODYPASS_LIST.PENDING:
    case ACTION.BODYPASS_LIST.SUCCESS:
      return { ...state, ...payload }
    case ACTION.BODYPASS_LIST.FAILURE:
      return { ...state, ...payload, tableList: init.tableList }
    case ACTION.BODYPASS_LIST.REMOVE_LIST:
      // remove list from array
      // payload = {id: , status: Approved|Rejected}
      if (!payload.id) {
        return
      }

      let list = []
      const findIndex = state.tableList.list.findIndex((item) => {
        return item.id === payload.id
      })
      if (findIndex !== -1) {
        if (findIndex === 0) {
          list = state.tableList.list.slice(1)
        } else if (findIndex === state.tableList.list.length - 1) {
          list = state.tableList.list.slice(0, findIndex)
        } else {
          const rest = state.tableList.list.splice(findIndex + 1)
          state.tableList.list.splice(findIndex)
          list = state.tableList.list.concat(rest)
        }
        list = list.map((value, index) => ({ ...value, no: index + 1 }))
      }

      return {
        status: state.status,
        message: state.status,
        tableList: { ...state.tableList, list: list },
      }
    default:
      return state
  }
}
