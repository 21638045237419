import ACTION, { SAFETY_BRIEFING_VIDEO_STATE } from './creator'

const init_safetyBriefingVideo = {
  status: SAFETY_BRIEFING_VIDEO_STATE.PENDING,
  message: '',
}
// const init_count = {
//   countWidgetData: {},
//   status: COUNTWIDGET_STATE.PENDING,
//   message: '',
// }

export const safetyBriefingVideoData = (state = init_safetyBriefingVideo, { type, payload }) => {
  switch (type) {
    case ACTION.SAFETY_BRIEFING_VIDEO.PENDING:
    case ACTION.SAFETY_BRIEFING_VIDEO.SUCCESS:
    case ACTION.SAFETY_BRIEFING_VIDEO.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
