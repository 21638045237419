const APPLICATION_VERIFICATION_ACTIONS = {
  APPLICATION_VERIFICATION: {
    PENDING: 'APPLICATION_VERIFICATION_PENDING',
    SUCCESS: 'APPLICATION_VERIFICATION_SUCCESS',
    FAILURE: 'APPLICATION_VERIFICATION_FAILURE',
  },
}

export const APPLICATION_VERIFICATION_STATE = {
  REFRESHED: 'APPLICATION_VERIFICATION_REFRESHED',
  IN_PROGRESS: 'APPLICATION_VERIFICATION_IN_PROGRESS',
  SUCCESS: 'APPLICATION_VERIFICATION_RETRIEVED',
  ERROR: 'APPLICATION_VERIFICATION_ERROR',
  PENDING: 'APPLICATION_VERIFICATION_PENDING',
}

export default APPLICATION_VERIFICATION_ACTIONS
