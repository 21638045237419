const AUXDASHBOARD_DATA_ACTIONS = {
  AUXDASHBOARD_DATA: {
    PENDING: 'AUXDASHBOARD_DATA_PENDING',
    SUCCESS: 'AUXDASHBOARD_DATA_SUCCESS',
    FAILURE: 'AUXDASHBOARD_DATA_FAILURE',
    UPDATE_LIST: 'AUXDASHBOARD_UPDATE_LIST',
  },
  PASS_INFO: {
    PENDING: 'AP_PASS_INFO_PENDING',
    SUCCESS: 'AP_PASS_INFO_SUCCESS',
    FAILURE: 'AP_PASS_INFO_FAILURE',
  },
}

export const AUXDASHBOARD_DATA_STATE = {
  REFRESHED: 'AUXDASHBOARD_DATA_REFRESHED',
  IN_PROGRESS: 'AUXDASHBOARD_DATA_IN_PROGRESS',
  SUCCESS: 'AUXDASHBOARD_DATA_RETRIEVED',
  ERROR: 'AUXDASHBOARD_DATA_ERROR',
  PENDING: 'AUXDASHBOARD_DATA_PENDING',
}
export const AP_PASS_INFO_DATA_STATE = {
  REFRESHED: 'AP_PASS_INFO_DATA_REFRESHED',
  IN_PROGRESS: 'AP_PASS_INFO_DATA_IN_PROGRESS',
  SUCCESS: 'AP_PASS_INFO_DATA_RETRIEVED',
  ERROR: 'AP_PASS_INFO_DATA_ERROR',
  PENDING: 'AP_PASS_INFO_DATA_PENDING',
}
export default AUXDASHBOARD_DATA_ACTIONS
