const SAFETYSECURITY_DATA_ACTIONS = {
  SAFETYSECURITY_DATA: {
    PENDING: 'SAFETYSECURITY_DATA_PENDING',
    SUCCESS: 'SAFETYSECURITY_DATA_SUCCESS',
    FAILURE: 'SAFETYSECURITY_DATA_FAILURE',
  },
  VERIFY: {
    PENDING: 'SAFETYSECURITY_VERIFY_PENDING',
    SUCCESS: 'SAFETYSECURITY_VERIFY_SUCCESS',
    FAILURE: 'SAFETYSECURITY_VERIFY_FAILURE',
  },
  REJECT: {
    PENDING: 'SAFETYSECURITY_REJECT_PENDING',
    SUCCESS: 'SAFETYSECURITY_REJECT_SUCCESS',
    FAILURE: 'SAFETYSECURITY_REJECT_FAILURE',
  },
}

export const SAFETYSECURITY_DATA_STATE = {
  REFRESHED: 'SAFETYSECURITY_DATA_REFRESHED',
  IN_PROGRESS: 'SAFETYSECURITY_DATA_IN_PROGRESS',
  SUCCESS: 'SAFETYSECURITY_DATA_RETRIEVED',
  ERROR: 'SAFETYSECURITY_DATA_ERROR',
  PENDING: 'SAFETYSECURITY_DATA_PENDING',
}
export const SAFETYSECURITY_VERIFY_STATE = {
  REFRESHED: 'SAFETYSECURITY_VERIFY_REFRESHED',
  IN_PROGRESS: 'SAFETYSECURITY_VERIFY_IN_PROGRESS',
  SUCCESS: 'SAFETYSECURITY_VERIFY_RETRIEVED',
  ERROR: 'SAFETYSECURITY_VERIFY_ERROR',
  PENDING: 'SAFETYSECURITY_VERIFY_PENDING',
}
export const SAFETYSECURITY_REJECT_STATE = {
  REFRESHED: 'SAFETYSECURITY_REJECT_REFRESHED',
  IN_PROGRESS: 'SAFETYSECURITY_REJECT_IN_PROGRESS',
  SUCCESS: 'SAFETYSECURITY_REJECT_RETRIEVED',
  ERROR: 'SAFETYSECURITY_REJECT_ERROR',
  PENDING: 'SAFETYSECURITY_REJECT_PENDING',
}

export default SAFETYSECURITY_DATA_ACTIONS
