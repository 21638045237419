import ACTION, { PAYMENT_CART_STATE } from './creator'

const init_paymentCart = {
  status: PAYMENT_CART_STATE.PENDING,
  message: '',
}
// const init_count = {
//   countWidgetData: {},
//   status: COUNTWIDGET_STATE.PENDING,
//   message: '',
// }

export const paymentCartData = (state = init_paymentCart, { type, payload }) => {
  switch (type) {
    case ACTION.PAYMENT_CART.PENDING:
    case ACTION.PAYMENT_CART.SUCCESS:
    case ACTION.PAYMENT_CART.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
