const USERDASHBOARD_DATA_ACTIONS = {
  USERDASHBOARD_DATA: {
    PENDING: 'USERDASHBOARD_DATA_PENDING',
    SUCCESS: 'USERDASHBOARD_DATA_SUCCESS',
    FAILURE: 'USERDASHBOARD_DATA_FAILURE',
    REMOVE: 'USERDASHBOARD_DATA_REMOVE',
  },
  STATUS_DATA: {
    PENDING: 'STATUS_DATA_PENDING',
    SUCCESS: 'STATUS_DATA_SUCCESS',
    FAILURE: 'STATUS_DATA_FAILURE',
  },
  PASS_INFO: {
    PENDING: 'USER_DASHBOARD_PASS_INFO_PENDING',
    SUCCESS: 'USER_DASHBOARD_PASS_INFO_SUCCESS',
    FAILURE: 'USER_DASHBOARD_PASS_INFO_FAILURE',
  },
}

export const USERDASHBOARD_DATA_STATE = {
  REFRESHED: 'USERDASHBOARD_DATA_REFRESHED',
  IN_PROGRESS: 'USERDASHBOARD_DATA_IN_PROGRESS',
  SUCCESS: 'USERDASHBOARD_DATA_RETRIEVED',
  ERROR: 'USERDASHBOARD_DATA_ERROR',
  PENDING: 'USERDASHBOARD_DATA_PENDING',
}

export const STATUS_DATA_STATE = {
  REFRESHED: 'STATUS_DATA_REFRESHED',
  IN_PROGRESS: 'STATUS_DATA_IN_PROGRESS',
  SUCCESS: 'STATUS_DATA_RETRIEVED',
  ERROR: 'STATUS_DATA_ERROR',
  PENDING: 'STATUS_DATA_PENDING',
}

export const PASS_INFO_DATA_STATE = {
  REFRESHED: 'PASS_INFO_DATA_REFRESHED',
  IN_PROGRESS: 'PASS_INFO_DATA_IN_PROGRESS',
  SUCCESS: 'PASS_INFO_DATA_RETRIEVED',
  ERROR: 'PASS_INFO_DATA_ERROR',
  PENDING: 'PASS_INFO_DATA_PENDING',
}

export default USERDASHBOARD_DATA_ACTIONS
