import ACTION, { VEHICLEPASS_DATA_STATE } from './creator'

const init = {
  status: VEHICLEPASS_DATA_STATE.PENDING,
  message: '',
  dataForm: [],
}

export const AuxVerificationApplicationByTenantVP = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.VEHICLEPASS_DATA.PENDING:
    case ACTION.VEHICLEPASS_DATA.SUCCESS:
    case ACTION.VEHICLEPASS_DATA.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const vpVerifyApplication = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.VERIFY.PENDING:
    case ACTION.VERIFY.SUCCESS:
    case ACTION.VERIFY.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const vpRejectApplication = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.REJECT.PENDING:
    case ACTION.REJECT.SUCCESS:
    case ACTION.REJECT.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
