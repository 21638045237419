const SUMMARY_BODYPASS_ACTIONS = {
  BODYPASS_SAFETY_BRIEFING: {
    PENDING: 'BODYPASS_SAFETY_BRIEFING_PENDING',
    SUCCESS: 'BODYPASS_SAFETY_BRIEFING_SUCCESS',
    FAILURE: 'BODYPASS_SAFETY_BRIEFING_FAILURE',
    UPDATE: 'BODYPASS_SAFETY_BRIEFING_UPDATE',
  },
  FORM_SUBMIT: {
    PENDING: 'FORM_SUBMIT_PENDING',
    SUCCESS: 'FORM_SUBMIT_SUCCESS',
    FAILURE: 'FORM_SUBMIT_FAILURE',
    UPDATE: 'FORM_SUBMIT_UPDATE',
  },
  VEHICLE_BODYPASS_FORM_SUBMIT: {
    PENDING: 'VEHICLE_BODYPASS_FORM_SUBMIT_PENDING',
    SUCCESS: 'VEHICLE_BODYPASS_FORM_SUBMIT_SUCCESS',
    FAILURE: 'VEHICLE_BODYPASS_FORM_SUBMIT_FAILURE',
    UPDATE: 'VEHICLE_BODYPASS_FORM_SUBMIT_UPDATE',
  },
}

export const BODYPASS_SAFETY_BRIEFING_STATE = {
  REFRESHED: 'BODYPASS_SAFETY_BRIEFING_REFRESHED',
  IN_PROGRESS: 'BODYPASS_SAFETY_BRIEFING_PROGRESS',
  SUCCESS: 'BODYPASS_SAFETY_BRIEFING_RETRIEVED',
  ERROR: 'BODYPASS_SAFETY_BRIEFING_ERROR',
  PENDING: 'BODYPASS_SAFETY_BRIEFING_PENDING',
}

export const VEHICLE_BODYPASS_FORM_SUBMIT_STATE = {
  REFRESHED: 'VEHICLE_BODYPASS_FORM_SUBMIT_REFRESHED',
  IN_PROGRESS: 'VEHICLE_BODYPASS_FORM_SUBMIT_PROGRESS',
  SUCCESS: 'VEHICLE_BODYPASS_FORM_SUBMIT_RETRIEVED',
  ERROR: 'VEHICLE_BODYPASS_FORM_SUBMIT_ERROR',
  PENDING: 'VEHICLE_BODYPASS_FORM_SUBMIT_PENDING',
}

export const FORM_SUBMIT_STATE = {
  REFRESHED: 'FORM_SUBMIT_REFRESHED',
  IN_PROGRESS: 'FORM_SUBMIT_PROGRESS',
  SUCCESS: 'FORM_SUBMIT_RETRIEVED',
  ERROR: 'FORM_SUBMIT_ERROR',
  PENDING: 'FORM_SUBMIT_PENDING',
}

export default SUMMARY_BODYPASS_ACTIONS
