import ACTION, { SIGNUP_STATE } from './creator'

const init = {
  result: {},
  status: SIGNUP_STATE.REFRESHED,
  message: '',
}

const signUp = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.SIGNUP.PENDING:
    case ACTION.SIGNUP.SUCCESS:
    case ACTION.SIGNUP.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}

export default signUp
