import ACTION, { APPLICATION_VERIFICATION_STATE } from './creator'

const init = {
  status: APPLICATION_VERIFICATION_STATE.PENDING,
  message: '',
  dataForm: {},
}

export const auxPoliceApplicationVerification = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.APPLICATION_VERIFICATION.PENDING:
    case ACTION.APPLICATION_VERIFICATION.SUCCESS:
    case ACTION.APPLICATION_VERIFICATION.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
