import ACTION, { PASSWORD_SUBMIT_STATE } from './creator'

const init_submit = {
  status: PASSWORD_SUBMIT_STATE.PENDING,
  message: '',
}

export const SubmitResetPasswordData = (state = init_submit, { type, payload }) => {
  switch (type) {
    case ACTION.PASSWORD_SUBMIT.PENDING:
    case ACTION.PASSWORD_SUBMIT.SUCCESS:
    case ACTION.PASSWORD_SUBMIT.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}

export default SubmitResetPasswordData
