import ACTION, { BODYPASS_LIST_STATE, BODYPASS_LIST_PENDING_STATE } from './creator'

const init = {
  status: BODYPASS_LIST_STATE.PENDING,
  message: '',
  tableList: [],
}
const init_bp_pending = {
  status: BODYPASS_LIST_PENDING_STATE.PENDING,
  message: '',
  tableList: {
    list: [],
    pagination: {
      page: 1,
      itemPerPage: 10,
      count: 0,
      totalCount: 0,
    },
  },
}

export const AuxBodyPassTableByTenant = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.BODYPASS_LIST.PENDING:
    case ACTION.BODYPASS_LIST.SUCCESS:
      return { ...state, ...payload }
    case ACTION.BODYPASS_LIST.FAILURE:
      return { ...state, ...payload, tableList: init.tableList }
    default:
      return state
  }
}

export const AuxBodyPassPendingTableByTenant = (state = init_bp_pending, { type, payload }) => {
  switch (type) {
    case ACTION.LIST_PENDING.PENDING:
    case ACTION.LIST_PENDING.SUCCESS:
    case ACTION.LIST_PENDING.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
