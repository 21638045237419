import ACTION, { APPLICATION_APPROVAL_STATE } from './creator'

const init = {
  status: APPLICATION_APPROVAL_STATE.PENDING,
  message: '',
  dataForm: {},
}

export const auxPoliceApplicationApproval = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.APPLICATION_APPROVAL.PENDING:
    case ACTION.APPLICATION_APPROVAL.SUCCESS:
    case ACTION.APPLICATION_APPROVAL.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
