const AUX_PAYMENTCART_ACTIONS = {
  AUX_PAYMENT_CART: {
    PENDING: 'AUX_PAYMENT_CART_PENDING',
    SUCCESS: 'AUX_PAYMENT_CART_SUCCESS',
    FAILURE: 'AUX_PAYMENT_CART_FAILURE',
    UPDATE: 'AUX_PAYMENT_CART_UPDATE',
  },
}

export const AUX_PAYMENT_CART_STATE = {
  REFRESHED: 'AUX_PAYMENT_CART_REFRESHED',
  IN_PROGRESS: 'AUX_PAYMENT_CART_PROGRESS',
  SUCCESS: 'AUX_PAYMENT_CART_RETRIEVED',
  ERROR: 'AUX_PAYMENT_CART_ERROR',
  PENDING: 'AUX_PAYMENT_CART_PENDING',
}

export default AUX_PAYMENTCART_ACTIONS
