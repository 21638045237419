const VEHICLEPASSAP_DATA_ACTIONS = {
  VEHICLEPASSAP_DATA: {
    PENDING: 'VEHICLEPASSAP_DATA_PENDING',
    SUCCESS: 'VEHICLEPASSAP_DATA_SUCCESS',
    FAILURE: 'VEHICLEPASSAP_DATA_FAILURE',
    UPDATE: 'VEHICLEPASSAP_DATA_UPDATE',
  },
  VERIFY: {
    PENDING: 'VEHICLEPASSAP_VERIFY_PENDING',
    SUCCESS: 'VEHICLEPASSAP_VERIFY_SUCCESS',
    FAILURE: 'VEHICLEPASSAP_VERIFY_FAILURE',
  },
  REJECT: {
    PENDING: 'VEHICLEPASSAP_REJECT_PENDING',
    SUCCESS: 'VEHICLEPASSAP_REJECT_SUCCESS',
    FAILURE: 'VEHICLEPASSAP_REJECT_FAILURE',
  },
}

export const VEHICLEPASSAP_DATA_STATE = {
  REFRESHED: 'VEHICLEPASSAP_DATA_REFRESHED',
  IN_PROGRESS: 'VEHICLEPASSAP_DATA_IN_PROGRESS',
  SUCCESS: 'VEHICLEPASSAP_DATA_RETRIEVED',
  ERROR: 'VEHICLEPASSAP_DATA_ERROR',
  PENDING: 'VEHICLEPASSAP_DATA_PENDING',
}
export const VEHICLEPASSAP_VERIFY_STATE = {
  REFRESHED: 'VEHICLEPASSAP_VERIFY_REFRESHED',
  IN_PROGRESS: 'VEHICLEPASSAP_VERIFY_IN_PROGRESS',
  SUCCESS: 'VEHICLEPASSAP_VERIFY_RETRIEVED',
  ERROR: 'VEHICLEPASSAP_VERIFY_ERROR',
  PENDING: 'VEHICLEPASSAP_VERIFY_PENDING',
}
export const VEHICLEPASSAP_REJECT_STATE = {
  REFRESHED: 'VEHICLEPASSAP_REJECT_REFRESHED',
  IN_PROGRESS: 'VEHICLEPASSAP_REJECT_IN_PROGRESS',
  SUCCESS: 'VEHICLEPASSAP_REJECT_RETRIEVED',
  ERROR: 'VEHICLEPASSAP_REJECT_ERROR',
  PENDING: 'VEHICLEPASSAP_REJECT_PENDING',
}

export default VEHICLEPASSAP_DATA_ACTIONS
