const SUMMARY_ACTIONS = {
  UPDATE_FORM_DATA: {
    PENDING: 'UPDATE_FORM_DATA_PENDING',
    SUCCESS: 'UPDATE_FORM_DATA_SUCCESS',
    FAILURE: 'UPDATE_FORM_DATA_FAILURE',
    UPDATE: 'UPDATE_FORM_DATA_UPDATE',
    CLEAR: 'UPDATE_FORM_DATA_CLEAR',
  },
  FORM_SUBMIT: {
    PENDING: 'FORM_SUBMIT_PENDING',
    SUCCESS: 'FORM_SUBMIT_SUCCESS',
    FAILURE: 'FORM_SUBMIT_FAILURE',
    UPDATE: 'FORM_SUBMIT_UPDATE',
  },
}

export const FORM_SUBMIT_STATE = {
  REFRESHED: 'FORM_SUBMIT_REFRESHED',
  IN_PROGRESS: 'FORM_SUBMIT_PROGRESS',
  SUCCESS: 'FORM_SUBMIT_RETRIEVED',
  ERROR: 'FORM_SUBMIT_ERROR',
  PENDING: 'FORM_SUBMIT_PENDING',
}

export default SUMMARY_ACTIONS
