import ACTION, { AUX_PAYMENT_CART_STATE } from './creator'

const init_paymentCart = {
  status: AUX_PAYMENT_CART_STATE.PENDING,
  message: '',
  data: [],
}

export const AuxPaymentCartData = (state = init_paymentCart, { type, payload }) => {
  switch (type) {
    case ACTION.AUX_PAYMENT_CART.PENDING:
    case ACTION.AUX_PAYMENT_CART.SUCCESS:
    case ACTION.AUX_PAYMENT_CART.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
