import ACTION, { HISTORY_STATE, APPLICATION_SUMMARY_STATE } from './creator'

const init = {
  status: HISTORY_STATE.PENDING,
  message: '',
  history: [],
  historyData: {
    list: [],
    pagination: {
      page: 1,
      itemPerPage: 5,
      count: 0,
      totalCount: 0,
    },
  },
}

export const userHistoryTableData = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.HISTORY.PENDING:
    case ACTION.HISTORY.SUCCESS:
      return { ...state, ...payload }
    case ACTION.HISTORY.FAILURE:
      return { ...state, ...payload, historyData: init.historyData }
    case ACTION.HISTORY.REMOVE:
      if (!payload.id) {
        return
      }

      let list = []
      const findIndex = state.historyData.list.findIndex((item) => {
        return item.id === payload.id
      })
      if (findIndex !== -1) {
        if (findIndex === 0) {
          list = state.historyData.list.slice(1)
        } else if (findIndex === state.historyData.list.length - 1) {
          list = state.historyData.list.slice(0, findIndex)
        } else {
          const rest = state.historyData.list.splice(findIndex + 1)
          state.historyData.list.splice(findIndex)
          list = state.historyData.list.concat(rest)
        }
        list = list.map((value, index) => ({ ...value, no: index + 1 }))
        return {
          status: state.status,
          message: state.status,
          historyData: { ...state.historyData, list: list },
        }
      }

      return state
    default:
      return state
  }
}

export const applicationPassSummary = (
  state = { status: APPLICATION_SUMMARY_STATE.PENDING, message: '', data: {} },
  { type, payload },
) => {
  switch (type) {
    case ACTION.APPLICATION_SUMMARY.PENDING:
    case ACTION.APPLICATION_SUMMARY.SUCCESS:
    case ACTION.APPLICATION_SUMMARY.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
