const CORE_ACTIONS = {
  TENANT: {
    PENDING: 'TENANT_PENDING',
    SUCCESS: 'TENANT_SUCCESS',
    FAILURE: 'TENANT_FAILURE',
    UPDATE: 'TENANT_UPDATE',
    ADD: 'TENANT_ADD',
    REMOVE: 'TENANT_REMOVE',
  },
  DEPARTMENT: {
    PENDING: 'DEPARTMENT_PENDING',
    SUCCESS: 'DEPARTMENT_SUCCESS',
    FAILURE: 'DEPARTMENT_FAILURE',
    UPDATE: 'DEPARTMENT_UPDATE',
  },
  WORKAREA: {
    PENDING: 'WORKAREA_PENDING',
    SUCCESS: 'WORKAREA_SUCCESS',
    FAILURE: 'WORKAREA_FAILURE',
    UPDATE: 'WORKAREA_UPDATE',
  },
  LOCATION: {
    PENDING: 'LOCATION_PENDING',
    SUCCESS: 'LOCATION_SUCCESS',
    FAILURE: 'LOCATION_FAILURE',
    UPDATE: 'LOCATION_UPDATE',
  },
  EMPLOYER_COMPANY: {
    PENDING: 'EMPLOYER_COMPANY_PENDING',
    SUCCESS: 'EMPLOYER_COMPANY_SUCCESS',
    FAILURE: 'EMPLOYER_COMPANY_FAILURE',
    UPDATE: 'EMPLOYER_COMPANY_UPDATE',
    ADD: 'EMPLOYER_COMPANY_ADD',
    REMOVE: 'EMPLOYER_COMPANY_REMOVE',
  },
  PHONE_CODE: {
    PENDING: 'PHONE_CODE_PENDING',
    SUCCESS: 'PHONE_CODE_SUCCESS',
    FAILURE: 'PHONE_CODE_FAILURE',
    UPDATE: 'PHONE_CODE_UPDATE',
  },
  COUNTRY: {
    PENDING: 'COUNTRY_PENDING',
    SUCCESS: 'COUNTRY_SUCCESS',
    FAILURE: 'COUNTRY_FAILURE',
    UPDATE: 'COUNTRY_UPDATE',
  },
  COUNTRY_STATE: {
    PENDING: 'COUNTRY_STATE_PENDING',
    SUCCESS: 'COUNTRY_STATE_SUCCESS',
    FAILURE: 'COUNTRY_STATE_FAILURE',
    UPDATE: 'COUNTRY_STATE_UPDATE',
  },
  LICENSE_CLASS: {
    PENDING: 'LICENSE_CLASS_PENDING',
    SUCCESS: 'LICENSE_CLASS_SUCCESS',
    FAILURE: 'LICENSE_CLASS_FAILURE',
    UPDATE: 'LICENSE_CLASS_UPDATE',
  },
  VEHICLE_TYPE: {
    PENDING: 'VEHICLE_TYPE_PENDING',
    SUCCESS: 'VEHICLE_TYPE_SUCCESS',
    FAILURE: 'VEHICLE_TYPE_FAILURE',
    UPDATE: 'VEHICLE_TYPE_UPDATE',
  },
  PASS_INFO: {
    PENDING: 'PASS_INFO_PENDING',
    SUCCESS: 'PASS_INFO_SUCCESS',
    FAILURE: 'PASS_INFO_FAILURE',
    UPDATE: 'PASS_INFO_UPDATE',
  },
  ITEM_INFO: {
    PENDING: 'ITEM_INFO_PENDING',
    SUCCESS: 'ITEM_INFO_SUCCESS',
    FAILURE: 'ITEM_INFO_FAILURE',
    UPDATE: 'ITEM_INFO_UPDATE',
    ADD: 'ITEM_INFO_ADD',
    REMOVE: 'ITEM_INFO_REMOVE',
  },
}

export const TENANT_STATE = {
  REFRESHED: 'TENANT_REFRESHED',
  IN_PROGRESS: 'TENANT_PROGRESS',
  SUCCESS: 'TENANT_RETRIEVED',
  ERROR: 'TENANT_ERROR',
  PENDING: 'TENANT_PENDING',
}
export const DEPARTMENT_STATE = {
  REFRESHED: 'DEPARTMENT_REFRESHED',
  IN_PROGRESS: 'DEPARTMENT_PROGRESS',
  SUCCESS: 'DEPARTMENT_RETRIEVED',
  ERROR: 'DEPARTMENT_ERROR',
  PENDING: 'DEPARTMENT_PENDING',
  UPDATE: 'DEPARTMENT_UPDATE',
}
export const WORKAREA_STATE = {
  REFRESHED: 'WORKAREA_REFRESHED',
  IN_PROGRESS: 'WORKAREA_PROGRESS',
  SUCCESS: 'WORKAREA_RETRIEVED',
  ERROR: 'WORKAREA_ERROR',
  PENDING: 'WORKAREA_PENDING',
}
export const LOCATION_STATE = {
  REFRESHED: 'LOCATION_REFRESHED',
  IN_PROGRESS: 'LOCATION_PROGRESS',
  SUCCESS: 'LOCATION_RETRIEVED',
  ERROR: 'LOCATION_ERROR',
  PENDING: 'LOCATION_PENDING',
  UPDATE: 'LOCATION_UPDATE',
}
export const EMPLOYER_COMPANY_STATE = {
  REFRESHED: 'EMPLOYER_COMPANY_REFRESHED',
  IN_PROGRESS: 'EMPLOYER_COMPANY_PROGRESS',
  SUCCESS: 'EMPLOYER_COMPANY_RETRIEVED',
  ERROR: 'EMPLOYER_COMPANY_ERROR',
  PENDING: 'EMPLOYER_COMPANY_PENDING',
  UPDATE: 'EMPLOYER_COMPANY_UPDATE',
}
export const PHONE_CODE_STATE = {
  REFRESHED: 'PHONE_CODE_REFRESHED',
  IN_PROGRESS: 'PHONE_CODE_PROGRESS',
  SUCCESS: 'PHONE_CODE_RETRIEVED',
  ERROR: 'PHONE_CODE_ERROR',
  PENDING: 'PHONE_CODE_PENDING',
  UPDATE: 'PHONE_CODE_UPDATE',
}
export const COUNTRY_STATE = {
  REFRESHED: 'COUNTRY_REFRESHED',
  IN_PROGRESS: 'COUNTRY_PROGRESS',
  SUCCESS: 'COUNTRY_RETRIEVED',
  ERROR: 'COUNTRY_ERROR',
  PENDING: 'COUNTRY_PENDING',
  UPDATE: 'COUNTRY_UPDATE',
}
export const COUNTRY_STATE_STATE = {
  REFRESHED: 'COUNTRY_STATE_REFRESHED',
  IN_PROGRESS: 'COUNTRY_STATE_PROGRESS',
  SUCCESS: 'COUNTRY_STATE_RETRIEVED',
  ERROR: 'COUNTRY_STATE_ERROR',
  PENDING: 'COUNTRY_STATE_PENDING',
  UPDATE: 'COUNTRY_STATE_UPDATE',
}
export const LICENSE_CLASS_STATE = {
  REFRESHED: 'LICENSE_CLASS_REFRESHED',
  IN_PROGRESS: 'LICENSE_CLASS_PROGRESS',
  SUCCESS: 'LICENSE_CLASS_RETRIEVED',
  ERROR: 'LICENSE_CLASS_ERROR',
  PENDING: 'LICENSE_CLASS_PENDING',
  UPDATE: 'LICENSE_CLASS_UPDATE',
}
export const VEHICLE_TYPE_STATE = {
  REFRESHED: 'VEHICLE_TYPE_REFRESHED',
  IN_PROGRESS: 'VEHICLE_TYPE_PROGRESS',
  SUCCESS: 'VEHICLE_TYPE_RETRIEVED',
  ERROR: 'VEHICLE_TYPE_ERROR',
  PENDING: 'VEHICLE_TYPE_PENDING',
  UPDATE: 'VEHICLE_TYPE_UPDATE',
}
export const PASS_INFO_STATE = {
  REFRESHED: 'PASS_INFO_REFRESHED',
  IN_PROGRESS: 'PASS_INFO_PROGRESS',
  SUCCESS: 'PASS_INFO_RETRIEVED',
  ERROR: 'PASS_INFO_ERROR',
  PENDING: 'PASS_INFO_PENDING',
  UPDATE: 'PASS_INFO_UPDATE',
}
export const ITEM_INFO_STATE = {
  REFRESHED: 'ITEM_INFO_REFRESHED',
  IN_PROGRESS: 'ITEM_INFO_PROGRESS',
  SUCCESS: 'ITEM_INFO_RETRIEVED',
  ERROR: 'ITEM_INFO_ERROR',
  PENDING: 'ITEM_INFO_PENDING',
  UPDATE: 'ITEM_INFO_UPDATE',
}

export default CORE_ACTIONS
