import ACTION, {
  BODYPASS_SAFETY_BRIEFING_STATE,
  FORM_SUBMIT_STATE,
  VEHICLE_BODYPASS_FORM_SUBMIT_STATE,
} from './creator'

const init_safetyBriefing = {
  status: BODYPASS_SAFETY_BRIEFING_STATE.PENDING,
  message: '',
  checked: false,
}

const init_submit = {
  status: FORM_SUBMIT_STATE.PENDING,
  message: '',
}

const init_submit_vehicle_bodypass = {
  status: VEHICLE_BODYPASS_FORM_SUBMIT_STATE.PENDING,
  message: '',
}

export const bodyPassSafetyBriefing = (state = init_safetyBriefing, { type, payload }) => {
  //console.log('payload.checked', payload.checked)
  switch (type) {
    case ACTION.BODYPASS_SAFETY_BRIEFING.PENDING:
    case ACTION.BODYPASS_SAFETY_BRIEFING.SUCCESS:
      return {
        ...state,
        status: payload.status,
        message: payload.message,
        safetyBrief: payload.checked,
      }
    case ACTION.BODYPASS_SAFETY_BRIEFING.FAILURE:
      return { ...state, ...bodyPassSafetyBriefing }
    default:
      return state
  }
}

export const submitNewBodyPassForm = (state = init_submit, { type, payload }) => {
  switch (type) {
    case ACTION.FORM_SUBMIT.PENDING:
    case ACTION.FORM_SUBMIT.SUCCESS:
    case ACTION.FORM_SUBMIT.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const submitVehicleBodyPassForm = (
  state = init_submit_vehicle_bodypass,
  { type, payload },
) => {
  switch (type) {
    case ACTION.VEHICLE_BODYPASS_FORM_SUBMIT.PENDING:
    case ACTION.VEHICLE_BODYPASS_FORM_SUBMIT.SUCCESS:
    case ACTION.VEHICLE_BODYPASS_FORM_SUBMIT.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
