const SAFETYBRIEFING_LIST_ACTIONS = {
  SAFETYBRIEFING_LIST: {
    PENDING: 'SAFETYBRIEFING_LIST_PENDING',
    SUCCESS: 'SAFETYBRIEFING_LIST_SUCCESS',
    FAILURE: 'SAFETYBRIEFING_LIST_FAILURE',
  },
}

export const SAFETYBRIEFING_LIST_STATE = {
  REFRESHED: 'SAFETYBRIEFING_LIST_REFRESHED',
  IN_PROGRESS: 'SAFETYBRIEFING_LIST_IN_PROGRESS',
  SUCCESS: 'SAFETYBRIEFING_LIST_RETRIEVED',
  ERROR: 'SAFETYBRIEFING_LIST_ERROR',
  PENDING: 'SAFETYBRIEFING_LIST_PENDING',
}

export default SAFETYBRIEFING_LIST_ACTIONS
