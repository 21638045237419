const BODYPASS_LIST_ACTIONS = {
  BODYPASS_LIST: {
    PENDING: 'BODYPASS_VERIFY_LIST_PENDING',
    SUCCESS: 'BODYPASS_VERIFY_LIST_SUCCESS',
    FAILURE: 'BODYPASS_VERIFY_LIST_FAILURE',
  },
  LIST_PENDING: {
    PENDING: 'BODYPASS_LIST_PENDING_PENDING',
    SUCCESS: 'BODYPASS_LIST_PENDING_SUCCESS',
    FAILURE: 'BODYPASS_LIST_PENDING_FAILURE',
  },
}

export const BODYPASS_LIST_STATE = {
  REFRESHED: 'BODYPASS_LIST_REFRESHED',
  IN_PROGRESS: 'BODYPASS_LIST_IN_PROGRESS',
  SUCCESS: 'BODYPASS_LIST_RETRIEVED',
  ERROR: 'BODYPASS_LIST_ERROR',
  PENDING: 'BODYPASS_LIST_PENDING',
}

export const BODYPASS_LIST_PENDING_STATE = {
  REFRESHED: 'BODYPASS_LIST_PENDING_REFRESHED',
  IN_PROGRESS: 'BODYPASS_LIST_PENDING_IN_PROGRESS',
  SUCCESS: 'BODYPASS_LIST_PENDING_RETRIEVED',
  ERROR: 'BODYPASS_LIST_PENDING_ERROR',
  PENDING: 'BODYPASS_LIST_PENDING_PENDING',
}

export default BODYPASS_LIST_ACTIONS
