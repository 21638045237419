import ACTION, {
  FORM_SAVE_STATE,
  UPLOAD_ROADTAX_FILE_STATE,
  UPLOAD_DRIVINGLICENSE_FILE_STATE,
  UPLOAD_DRIVINGLICENSEBACK_FILE_STATE,
  UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE_STATE,
  GET_VEHICLE_TYPE_DATA_STATE,
  GET_LICENSE_CLASS_DATA_STATE,
} from './creator'

const init_save = {
  status: FORM_SAVE_STATE.PENDING,
  message: '',
}

const init_roadtax = {
  status: UPLOAD_ROADTAX_FILE_STATE.PENDING,
  message: '',
}

const init_drivinglicense = {
  status: UPLOAD_DRIVINGLICENSE_FILE_STATE.PENDING,
  message: '',
}

const init_drivinglicenseback = {
  status: UPLOAD_DRIVINGLICENSEBACK_FILE_STATE.PENDING,
  message: '',
}

const init_additionaldrivinglicense = {
  status: UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE_STATE.PENDING,
  message: '',
}

const init_update = {
  noPlat: '',
  vehicleType: [],
  licenseClass: [],
  roadTaxExpired: '',
  paymentMethod: [
    {
      id: '2',
      value: 'Cash',
      label: 'Cash / Tunai',
      checked: true,
      disabled: false,
    },
    {
      id: '3',
      value: 'Online',
      label: 'Online / Atas Talian',
      checked: false,
      disabled: true,
    },
    {
      id: '1',
      value: 'Company',
      label: 'Company / Syarikat',
      checked: false,
      disabled: true,
    },
  ],
  roadTax: '',
  drivingLicense: '',
  additionalDrivingLicense: '',
  formIdResponse: '',
  nextPage: false,
  licenseExpired: '',
}

const init_vehicleType = {
  status: GET_VEHICLE_TYPE_DATA_STATE.PENDING,
  message: '',
  data: [],
}

const init_licenseClass = {
  status: GET_LICENSE_CLASS_DATA_STATE.PENDING,
  message: '',
  data: [],
}
export const saveNewRegistrationBodyVehiclePassData = (state = init_save, { type, payload }) => {
  switch (type) {
    case ACTION.FORM_SAVE.PENDING:
    case ACTION.FORM_SAVE.SUCCESS:
    case ACTION.FORM_SAVE.FAILURE:
    case ACTION.FORM_GETID.SUCCESS:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const uploadBodyVehicleRoadTaxFile = (state = init_roadtax, { type, payload }) => {
  switch (type) {
    case ACTION.UPLOAD_ROADTAX_FILE.PENDING:
    case ACTION.UPLOAD_ROADTAX_FILE.SUCCESS:
    case ACTION.UPLOAD_ROADTAX_FILE.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const uploadBodyVehicleDrivingLicenseFile = (
  state = init_drivinglicense,
  { type, payload },
) => {
  switch (type) {
    case ACTION.UPLOAD_DRIVINGLICENSE_FILE.PENDING:
    case ACTION.UPLOAD_DRIVINGLICENSE_FILE.SUCCESS:
    case ACTION.UPLOAD_DRIVINGLICENSE_FILE.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const uploadBodyVehicleDrivingLicenseBackFile = (
  state = init_drivinglicenseback,
  { type, payload },
) => {
  switch (type) {
    case ACTION.UPLOAD_DRIVINGLICENSEBACK_FILE.PENDING:
    case ACTION.UPLOAD_DRIVINGLICENSEBACK_FILE.SUCCESS:
    case ACTION.UPLOAD_DRIVINGLICENSEBACK_FILE.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const uploadBodyVehicleAdditionalDrivingLicenseFile = (
  state = init_additionaldrivinglicense,
  { type, payload },
) => {
  switch (type) {
    case ACTION.UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE.PENDING:
    case ACTION.UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE.SUCCESS:
    case ACTION.UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const updateBodyVehicleFormData = (state = init_update, { type, payload }) => {
  switch (type) {
    case ACTION.UPDATE_FORM_DATA.SUCCESS:
      return { ...state, ...payload }
    case ACTION.UPDATE_FORM_DATA.CLEAR:
      return init_update
    default:
      return state
  }
}

export const getBodyVehicleTypeData = (state = init_vehicleType, { type, payload }) => {
  switch (type) {
    case ACTION.GET_VEHICLE_TYPE_DATA.PENDING:
    case ACTION.GET_VEHICLE_TYPE_DATA.SUCCESS:
      return {
        ...state,
        status: payload.status,
        message: payload.message,
        data: payload.data,
        init_update: { ...init_update, vehicleType: payload.data },
      }
    case ACTION.GET_VEHICLE_TYPE_DATA.FAILURE:
      return { ...state, ...getBodyVehicleTypeData }
    default:
      return state
  }
}

export const getBodyLicenseClassData = (state = init_licenseClass, { type, payload }) => {
  switch (type) {
    case ACTION.GET_LICENSE_CLASS_DATA.PENDING:
    case ACTION.GET_LICENSE_CLASS_DATA.SUCCESS:
      return {
        ...state,
        status: payload.status,
        message: payload.message,
        data: payload.data,
        init_update: { ...init_update, licenseClass: payload.data },
      }
    case ACTION.GET_LICENSE_CLASS_DATA.FAILURE:
      return { ...state, ...getBodyLicenseClassData }
    default:
      return state
  }
}
