const RENEW_BODY_VEHICLE_ACTIONS = {
  FORM_SAVE_RENEW: {
    PENDING: 'FORM_SAVE_RENEW_PENDING',
    SUCCESS: 'FORM_SAVE_RENEW_SUCCESS',
    FAILURE: 'FORM_SAVE_RENEW_FAILURE',
    UPDATE: 'FORM_SAVE_RENEW_UPDATE',
  },
  FORM_NEXT: {
    PENDING: 'FORM_NEXT_PENDING',
    SUCCESS: 'FORM_NEXT_SUCCESS',
    FAILURE: 'FORM_NEXT_FAILURE',
    UPDATE: 'FORM_NEXT_UPDATE',
  },
  UPDATE_FORM_DATA_RENEW: {
    PENDING: 'UPDATE_FORM_DATA_RENEW_PENDING',
    SUCCESS: 'UPDATE_FORM_DATA_RENEW_SUCCESS',
    FAILURE: 'UPDATE_FORM_DATA_RENEW_FAILURE',
    UPDATE: 'UPDATE_FORM_DATA_RENEW_UPDATE',
    CLEAR: 'UPDATE_FORM_DATA_RENEW_CLEAR',
  },
  FORM_GETID: {
    PENDING: 'FORM_GETID_PENDING',
    SUCCESS: 'FORM_GETID_SUCCESS',
    FAILURE: 'FORM_GETID_FAILURE',
    UPDATE: 'FORM_GETID_UPDATE',
  },
}

export const FORM_SAVE_RENEW_STATE = {
  REFRESHED: 'FORM_SAVE_RENEW_REFRESHED',
  IN_PROGRESS: 'FORM_SAVE_RENEW_PROGRESS',
  SUCCESS: 'FORM_SAVE_RENEW_RETRIEVED',
  ERROR: 'FORM_SAVE_RENEW_ERROR',
  PENDING: 'FORM_SAVE_RENEW_PENDING',
}

export const FORM_NEXT_STATE = {
  REFRESHED: 'FORM_NEXT_REFRESHED',
  IN_PROGRESS: 'FORM_NEXT_PROGRESS',
  SUCCESS: 'FORM_NEXT_RETRIEVED',
  ERROR: 'FORM_NEXT_ERROR',
  PENDING: 'FORM_NEXT_PENDING',
}

export const UPDATE_FORM_DATA_RENEW_STATE = {
  REFRESHED: 'UPDATE_FORM_DATA_RENEW_REFRESHED',
  IN_PROGRESS: 'UPDATE_FORM_DATA_RENEW_PROGRESS',
  SUCCESS: 'UPDATE_FORM_DATA_RENEW_RETRIEVED',
  ERROR: 'UPDATE_FORM_DATA_RENEW_ERROR',
  PENDING: 'UPDATE_FORM_DATA_RENEW_PENDING',
}

export const FORM_GETID_STATE = {
  REFRESHED: 'FORM_GETID_REFRESHED',
  IN_PROGRESS: 'FORM_GETID_PROGRESS',
  SUCCESS: 'FORM_GETID_RETRIEVED',
  ERROR: 'FORM_GETID_ERROR',
  PENDING: 'FORM_GETID_PENDING',
}

export default RENEW_BODY_VEHICLE_ACTIONS
