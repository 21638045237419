const SAFETYBRIEFING_ACTIONS = {
  SAFETY_BRIEFING_VIDEO: {
    PENDING: 'SAFETY_BRIEFING_VIDEO_PENDING',
    SUCCESS: 'SAFETY_BRIEFING_VIDEO_SUCCESS',
    FAILURE: 'SAFETY_BRIEFING_VIDEO_FAILURE',
    UPDATE: 'SAFETY_BRIEFING_VIDEO_UPDATE',
  },
}

export const SAFETY_BRIEFING_VIDEO_STATE = {
  REFRESHED: 'SAFETY_BRIEFING_VIDEO_REFRESHED',
  IN_PROGRESS: 'SAFETY_BRIEFING_VIDEO_PROGRESS',
  SUCCESS: 'SAFETY_BRIEFING_VIDEO_RETRIEVED',
  ERROR: 'SAFETY_BRIEFING_VIDEO_ERROR',
  PENDING: 'SAFETY_BRIEFING_VIDEO_PENDING',
}

export default SAFETYBRIEFING_ACTIONS
