import ACTION, {
  SAFETYSECURITY_DATA_STATE,
  SAFETYSECURITY_REJECT_STATE,
  SAFETYSECURITY_VERIFY_STATE,
} from './creator'

const init = {
  status: SAFETYSECURITY_DATA_STATE.PENDING,
  message: '',
  dataForm: {
    statusVerification: '',
    fullName: '',
    nationality: '',
    icNumber: '',
    passportNumber: '',
    contactNoCode: '',
    contactNo: '',
    employerCompany: '',
    startDate: '',
    endDate: '',
    paymentMethod: '',
    tenantName: '',
    item: '',
    totalPayment: '',
  },
}
const init_verify = {
  status: SAFETYSECURITY_VERIFY_STATE.PENDING,
  message: '',
}
const init_reject = {
  status: SAFETYSECURITY_REJECT_STATE.PENDING,
  message: '',
}

export const tenantSafetySecurityPassDataList = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.SAFETYSECURITY_DATA.PENDING:
    case ACTION.SAFETYSECURITY_DATA.SUCCESS:
    case ACTION.SAFETYSECURITY_DATA.FAILURE:
      return { ...state, ...payload }
    case ACTION.SAFETYSECURITY_DATA.UPDATE:
      let _state = { ...state }
      _state.dataForm = { ...state.dataForm, ...payload }
      return _state
    default:
      return state
  }
}
export const spVerifyApplication = (state = init_verify, { type, payload }) => {
  switch (type) {
    case ACTION.VERIFY.PENDING:
    case ACTION.VERIFY.SUCCESS:
    case ACTION.VERIFY.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
export const spRejectApplication = (state = init_reject, { type, payload }) => {
  switch (type) {
    case ACTION.REJECT.PENDING:
    case ACTION.REJECT.SUCCESS:
    case ACTION.REJECT.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
