import ACTION, { SAFETYSECURITY_LIST_STATE } from './creator'

const init = {
  status: SAFETYSECURITY_LIST_STATE.PENDING,
  message: '',
  tableList: [],
}

export const tenantSafetySecurityTableDataList = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.SAFETYSECURITY_LIST.PENDING:
    case ACTION.SAFETYSECURITY_LIST.SUCCESS:
    case ACTION.SAFETYSECURITY_LIST.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
