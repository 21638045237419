import ACTION, { BODYPASS_DATA_STATE } from './creator'

const init = {
  status: BODYPASS_DATA_STATE.PENDING,
  message: '',
  dataForm: [],
}

export const AuxVerificationApplicationByTenantBP = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.BODYPASS_DATA.PENDING:
    case ACTION.BODYPASS_DATA.SUCCESS:
    case ACTION.BODYPASS_DATA.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const bpVerifyApplication = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.VERIFY.PENDING:
    case ACTION.VERIFY.SUCCESS:
    case ACTION.VERIFY.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
export const bpRejectApplication = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.REJECT.PENDING:
    case ACTION.REJECT.SUCCESS:
    case ACTION.REJECT.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
