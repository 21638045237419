const SIGNUP_ACTIONS = {
  SIGNUP: {
    PENDING: 'SIGNUP_PENDING',
    SUCCESS: 'SIGNUP_SUCCESS',
    FAILURE: 'SIGNUP_FAILURE',
  },
}

export const SIGNUP_STATE = {
  REFRESHED: 'SIGNUP_REFRESHED',
  IN_PROGRESS: 'SIGNUP_IN_PROGRESS',
  UNAUTHORIZED: 'SIGNUP_UNAUTHORIZED',
  SUCCESS: 'SIGNUP_RETRIEVED',
  AUTHORIZED: 'SIGNUP_AUTHORIZED',
  ERROR: 'SIGNUP_ERROR',
}

export default SIGNUP_ACTIONS
