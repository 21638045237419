import ACTION, {
  BODYPASS_DATA_STATE,
  BODYPASS_VERIFY_STATE,
  BODYPASS_REJECT_STATE,
} from './creator'

const init = {
  status: BODYPASS_DATA_STATE.PENDING,
  message: '',
  dataForm: {
    photoFilename: '',
    fullname: '',
    nationality: '',
    email: '',
    icNumber: '',
    gender: '',
    contactNumber: '',
    residentAddress: '',
    employerCompany: '',
    occupation: '',
    tenant: '',
    companyNo: '',
    address: '',
    location: [],
    workPass: '',
    startDate: '',
    endDate: '',
    platNo: '',
    vehicleType: '',
    roadTaxExpired: '',
    ic: '',
    drivingLicense: '',
    roadTax: '',
    additionalDrivingLicense: '',
    passport: '',
    workPermit: '',
    workAcceptance: '',
    releaseLetter: '',
    safetyBrief: '',
    paymentMethod: '',
    newRegBP: '',
    newRegVP: '',
    renewSB: '',
    totalPayment: '',
    oldPassport: '',
    oldPassportExpired: '',
    newPassport: '',
    newPassportExpired: '',
    flexCheckChecked: [],
    statusVerification: 'Pending',
  },
}

const init_verify = {
  status: BODYPASS_VERIFY_STATE.PENDING,
  message: '',
}
const init_reject = {
  status: BODYPASS_REJECT_STATE.PENDING,
  message: '',
}

export const tenantBodyPassDataList = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.BODYPASS_DATA.PENDING:
    case ACTION.BODYPASS_DATA.SUCCESS:
    case ACTION.BODYPASS_DATA.FAILURE:
      return { ...state, ...payload }
    case ACTION.BODYPASS_DATA.UPDATE:
      let _state = { ...state }
      _state.dataForm = { ...state.dataForm, ...payload }
      return _state
    default:
      return state
  }
}
export const bpVerifyApplication = (state = init_verify, { type, payload }) => {
  switch (type) {
    case ACTION.VERIFY.PENDING:
    case ACTION.VERIFY.SUCCESS:
    case ACTION.VERIFY.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
export const bpRejectApplication = (state = init_reject, { type, payload }) => {
  switch (type) {
    case ACTION.REJECT.PENDING:
    case ACTION.REJECT.SUCCESS:
    case ACTION.REJECT.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
