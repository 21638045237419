import ACTION, { FORM_SUBMIT_STATE } from './creator'

const init_update = {
  fullName: '',
  icNum: '',
  language: [],
  officerName: [],
  reason: [],
  formIdResponse: '',
}
const init_submit = {
  status: FORM_SUBMIT_STATE.PENDING,
  message: '',
}

export const updateLostCardSummaryFormData = (state = init_update, { type, payload }) => {
  switch (type) {
    case ACTION.UPDATE_FORM_DATA.SUCCESS:
      return { ...state, ...payload }
    case ACTION.UPDATE_FORM_DATA.CLEAR:
      return init_update
    default:
      return state
  }
}

export const submitLostCardForm = (state = init_submit, { type, payload }) => {
  switch (type) {
    case ACTION.FORM_SUBMIT.PENDING:
    case ACTION.FORM_SUBMIT.SUCCESS:
    case ACTION.FORM_SUBMIT.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
