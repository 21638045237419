import ACTION, { TENANTDASHBOARD_DATA_STATE, TENANT_PASS_INFO_DATA_STATE } from './creator'

const init = {
  status: TENANTDASHBOARD_DATA_STATE.PENDING,
  message: '',
  tableList: [],
}
const pass_info_data_init = {
  status: TENANT_PASS_INFO_DATA_STATE.PENDING,
  message: '',
  data: {
    bodyPass: {
      approved: 0,
      pending: 0,
      rejected: 0,
    },
    vehiclePass: {
      approved: 0,
      pending: 0,
      rejected: 0,
    },
    safetyBriefing: {
      approved: 0,
      pending: 0,
      rejected: 0,
    },
  },
}

export const tenantDashboardDataList = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.TENANTDASHBOARD_DATA.PENDING:
    case ACTION.TENANTDASHBOARD_DATA.SUCCESS:
    case ACTION.TENANTDASHBOARD_DATA.FAILURE:
      return { ...state, ...payload }
    case ACTION.TENANTDASHBOARD_DATA.UPDATE_LIST:
      // payload = {id: , status: Approved|Rejected}
      if (!payload.id) {
        return
      }
      const findIndex = state.tableList.findIndex((item) => {
        return item.id === payload.id
      })
      if (findIndex !== -1) {
        // state.tableList[findIndex].statusApplication = payload.status
        const data = { ...state.tableList[findIndex], ...payload }
        state.tableList[findIndex] = data
      }

      return { ...state }
    default:
      return state
  }
}

export const tenantPassInfoData = (state = pass_info_data_init, { type, payload }) => {
  switch (type) {
    case ACTION.PASS_INFO.PENDING:
    case ACTION.PASS_INFO.SUCCESS:
    case ACTION.PASS_INFO.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
