import ACTION, {
  TENANT_STATE,
  DEPARTMENT_STATE,
  WORKAREA_STATE,
  LOCATION_STATE,
  EMPLOYER_COMPANY_STATE,
  PHONE_CODE_STATE,
  COUNTRY_STATE,
  COUNTRY_STATE_STATE,
  LICENSE_CLASS_STATE,
  VEHICLE_TYPE_STATE,
  ITEM_INFO_STATE,
} from './creator'

const init_tenant = {
  status: TENANT_STATE.PENDING,
  message: '',
  data: [],
}
const init_department = {
  status: DEPARTMENT_STATE.PENDING,
  message: '',
  data: [],
}
const init_workArea = {
  status: WORKAREA_STATE.PENDING,
  message: '',
  data: [],
}
const init_location = {
  status: LOCATION_STATE.PENDING,
  message: '',
  data: [],
}
const init_company = {
  status: EMPLOYER_COMPANY_STATE.PENDING,
  message: '',
  data: [],
}
const init_phoneCode = {
  status: PHONE_CODE_STATE.PENDING,
  message: '',
  data: [],
}
const init_country = {
  status: COUNTRY_STATE.PENDING,
  message: '',
  data: [],
}
const init_country_state = {
  status: COUNTRY_STATE_STATE.PENDING,
  message: '',
  data: [],
}
const init_license_class = {
  status: LICENSE_CLASS_STATE.PENDING,
  message: '',
  data: [],
}
const init_vehicle_type = {
  status: VEHICLE_TYPE_STATE.PENDING,
  message: '',
  data: [],
}
const init_item = {
  status: ITEM_INFO_STATE.PENDING,
  message: '',
  data: [],
}

export const tenantData = (state = init_tenant, { type, payload }) => {
  switch (type) {
    case ACTION.TENANT.PENDING:
    case ACTION.TENANT.SUCCESS:
    case ACTION.TENANT.FAILURE:
      return { ...state, ...payload }
    case ACTION.TENANT.ADD:
      const _state = { ...state }
      _state.data.push(payload)
      return _state
    case ACTION.TENANT.UPDATE:
      const index = state.data.findIndex((element) => element.id === payload.id)
      const data = state.data
      data[index] = payload
      if (index) {
        return {
          ...state,

          data: data,
        }
      }
      return state
    case ACTION.TENANT.REMOVE:
      if (!payload.id) {
        return
      }

      let list = []
      const findIndex = state.data.findIndex((item) => {
        return item.id === payload.id
      })
      if (findIndex !== -1) {
        if (findIndex === 0) {
          list = state.data.slice(1)
        } else if (findIndex === state.data.length - 1) {
          list = state.data.slice(0, findIndex)
        } else {
          const rest = state.data.splice(findIndex + 1)
          state.data.splice(findIndex)
          list = state.data.concat(rest)
        }
        list = list.map((value, index) => ({ ...value, no: index + 1 }))
        return {
          status: state.status,
          message: state.status,
          data: list,
        }
      }

      return state
    default:
      return state
  }
}
export const tenantDepartmentData = (state = init_department, { type, payload }) => {
  switch (type) {
    case ACTION.DEPARTMENT.PENDING:
    case ACTION.DEPARTMENT.SUCCESS:
    case ACTION.DEPARTMENT.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
export const workAreaData = (state = init_workArea, { type, payload }) => {
  switch (type) {
    case ACTION.WORKAREA.PENDING:
    case ACTION.WORKAREA.SUCCESS:
    case ACTION.WORKAREA.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
export const locationData = (state = init_location, { type, payload }) => {
  switch (type) {
    case ACTION.LOCATION.PENDING:
    case ACTION.LOCATION.SUCCESS:
    case ACTION.LOCATION.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
export const employerCompanyData = (state = init_company, { type, payload }) => {
  switch (type) {
    case ACTION.EMPLOYER_COMPANY.PENDING:
    case ACTION.EMPLOYER_COMPANY.SUCCESS:
    case ACTION.EMPLOYER_COMPANY.FAILURE:
      return { ...state, ...payload }
    case ACTION.EMPLOYER_COMPANY.ADD:
      const _state = { ...state }
      _state.data.push(payload)
      return _state
    case ACTION.EMPLOYER_COMPANY.UPDATE:
      const index = state.data.findIndex((element) => element.id === payload.id)
      const data = state.data
      data[index] = payload
      if (index) {
        return {
          ...state,

          data: data,
        }
      }
      return state
    case ACTION.EMPLOYER_COMPANY.REMOVE:
      if (!payload.id) {
        return
      }

      let list = []
      const findIndex = state.data.findIndex((item) => {
        return item.id === payload.id
      })
      if (findIndex !== -1) {
        if (findIndex === 0) {
          list = state.data.slice(1)
        } else if (findIndex === state.data.length - 1) {
          list = state.data.slice(0, findIndex)
        } else {
          const rest = state.data.splice(findIndex + 1)
          state.data.splice(findIndex)
          list = state.data.concat(rest)
        }
        list = list.map((value, index) => ({ ...value, no: index + 1 }))
        return {
          status: state.status,
          message: state.status,
          data: list,
        }
      }

      return state
    default:
      return state
  }
}
export const phoneCodeData = (state = init_phoneCode, { type, payload }) => {
  switch (type) {
    case ACTION.PHONE_CODE.PENDING:
    case ACTION.PHONE_CODE.SUCCESS:
    case ACTION.PHONE_CODE.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
export const countryData = (state = init_country, { type, payload }) => {
  switch (type) {
    case ACTION.COUNTRY.PENDING:
    case ACTION.COUNTRY.SUCCESS:
    case ACTION.COUNTRY.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
export const countryStateData = (state = init_country_state, { type, payload }) => {
  switch (type) {
    case ACTION.COUNTRY_STATE.PENDING:
    case ACTION.COUNTRY_STATE.SUCCESS:
    case ACTION.COUNTRY_STATE.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
export const licenseClassData = (state = init_license_class, { type, payload }) => {
  switch (type) {
    case ACTION.LICENSE_CLASS.PENDING:
    case ACTION.LICENSE_CLASS.SUCCESS:
    case ACTION.LICENSE_CLASS.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
export const vehicleTypeData = (state = init_vehicle_type, { type, payload }) => {
  switch (type) {
    case ACTION.VEHICLE_TYPE.PENDING:
    case ACTION.VEHICLE_TYPE.SUCCESS:
    case ACTION.VEHICLE_TYPE.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
export const itemInfoData = (state = init_item, { type, payload }) => {
  switch (type) {
    case ACTION.ITEM_INFO.PENDING:
    case ACTION.ITEM_INFO.SUCCESS:
    case ACTION.ITEM_INFO.FAILURE:
      return { ...state, ...payload }
    case ACTION.ITEM_INFO.ADD:
      const _state = { ...state }
      _state.data.push(payload)
      return _state
    case ACTION.ITEM_INFO.UPDATE:
      const index = state.data.findIndex((element) => element.id === payload.id)
      const data = state.data
      data[index] = { ...data[index], ...payload }
      if (index) {
        return {
          ...state,

          data: data,
        }
      }
      return state
    case ACTION.ITEM_INFO.REMOVE:
      if (!payload.id) {
        return
      }

      let list = []
      const findIndex = state.data.findIndex((item) => {
        return item.id === payload.id
      })
      if (findIndex !== -1) {
        if (findIndex === 0) {
          list = state.data.slice(1)
        } else if (findIndex === state.data.length - 1) {
          list = state.data.slice(0, findIndex)
        } else {
          const rest = state.data.splice(findIndex + 1)
          state.data.splice(findIndex)
          list = state.data.concat(rest)
        }
        list = list.map((value, index) => ({ ...value, no: index + 1 }))
        return {
          status: state.status,
          message: state.status,
          data: list,
        }
      }

      return state
    default:
      return state
  }
}
