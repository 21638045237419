const SAFETYBRIEFING_ACTIONS = {
  BRIEFINGINFO: {
    PENDING: 'BRIEFINGINFO_PENDING',
    SUCCESS: 'BRIEFINGINFO_SUCCESS',
    FAILURE: 'BRIEFINGINFO_FAILURE',
    UPDATE: 'BRIEFINGINFO_UPDATE',
  },
  BRIEFINGINFO_SUBMIT: {
    PENDING: 'BRIEFINGINFO_SUBMIT_PENDING',
    SUCCESS: 'BRIEFINGINFO_SUBMIT_SUCCESS',
    FAILURE: 'BRIEFINGINFO_SUBMIT_FAILURE',
    UPDATE: 'BRIEFINGINFO_SUBMIT_UPDATE',
  },
  USER_PASS_INFO: {
    PENDING: 'USER_PASS_INFO_PENDING',
    SUCCESS: 'USER_PASS_INFO_SUCCESS',
    FAILURE: 'USER_PASS_INFO_FAILURE',
    UPDATE: 'USER_PASS_INFO_UPDATE',
  },
  PASS_PRICE: {
    PENDING: 'PASS_PRICE_PENDING',
    SUCCESS: 'PASS_PRICE_SUCCESS',
    FAILURE: 'PASS_PRICE_FAILURE',
    UPDATE: 'PASS_PRICE_UPDATE',
  },
  SAFETY_BRIEFING_FORM_SUBMIT: {
    PENDING: 'SAFETY_BRIEFING_FORM_SUBMIT_PENDING',
    SUCCESS: 'SAFETY_BRIEFING_FORM_SUBMIT_SUCCESS',
    FAILURE: 'SAFETY_BRIEFING_FORM_SUBMIT_FAILURE',
    UPDATE: 'SAFETY_BRIEFING_FORM_SUBMIT_UPDATE',
  },
}

export const BRIEFINGINFO_STATE = {
  REFRESHED: 'BRIEFINGINFO_REFRESHED',
  IN_PROGRESS: 'BRIEFINGINFO_PROGRESS',
  SUCCESS: 'BRIEFINGINFO_RETRIEVED',
  ERROR: 'BRIEFINGINFO_ERROR',
  PENDING: 'BRIEFINGINFO_PENDING',
}

export const BRIEFINGINFO_SUBMIT_STATE = {
  REFRESHED: 'BRIEFINGINFO_SUBMIT_REFRESHED',
  IN_PROGRESS: 'BRIEFINGINFO_SUBMIT_PROGRESS',
  SUCCESS: 'BRIEFINGINFO_SUBMIT_RETRIEVED',
  ERROR: 'BRIEFINGINFO_SUBMIT_ERROR',
  PENDING: 'BRIEFINGINFO_SUBMIT_PENDING',
}

export const USER_PASS_INFO_STATE = {
  REFRESHED: 'USER_PASS_INFO_REFRESHED',
  IN_PROGRESS: 'USER_PASS_INFO_PROGRESS',
  SUCCESS: 'USER_PASS_INFO_RETRIEVED',
  ERROR: 'USER_PASS_INFO_ERROR',
  PENDING: 'USER_PASS_INFO_PENDING',
}

export const PASS_PRICE_STATE = {
  REFRESHED: 'PASS_PRICE_REFRESHED',
  IN_PROGRESS: 'PASS_PRICE_PROGRESS',
  SUCCESS: 'PASS_PRICE_RETRIEVED',
  ERROR: 'PASS_PRICE_ERROR',
  PENDING: 'PASS_PRICE_PENDING',
}

export const SAFETY_BRIEFING_FORM_SUBMIT_STATE = {
  REFRESHED: 'SAFETY_BRIEFING_FORM_SUBMIT_REFRESHED',
  IN_PROGRESS: 'SAFETY_BRIEFING_FORM_SUBMIT_PROGRESS',
  SUCCESS: 'SAFETY_BRIEFING_FORM_SUBMIT_RETRIEVED',
  ERROR: 'SAFETY_BRIEFING_FORM_SUBMIT_ERROR',
  PENDING: 'SAFETY_BRIEFING_FORM_SUBMIT_PENDING',
}

export default SAFETYBRIEFING_ACTIONS
