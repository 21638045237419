import ACTION, { SAFETYSECURITY_DATA_STATE } from './creator'

const init = {
  status: SAFETYSECURITY_DATA_STATE.PENDING,
  message: '',
  dataForm: {
    statusApproval: '',
    fullName: '',
    nationality: '',
    icNumber: '',
    passportNumber: '',
    contactNoCode: '',
    contactNo: '',
    employerCompany: '',
    startDate: '',
    endDate: '',
    paymentMethod: '',
    tenantName: '',
    item: '',
    totalPayment: '',
  },
}

export const auxPoliceSafetySecurityPassDataList = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.SAFETYSECURITY_DATA.PENDING:
    case ACTION.SAFETYSECURITY_DATA.SUCCESS:
    case ACTION.SAFETYSECURITY_DATA.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
