const RESET_PASSWORD_ACTIONS = {
  PASSWORD_SUBMIT: {
    PENDING: 'PASSWORD_SUBMIT_PENDING',
    SUCCESS: 'PASSWORD_SUBMIT_SUCCESS',
    FAILURE: 'PASSWORD_SUBMIT_FAILURE',
    UPDATE: 'PASSWORD_SUBMIT_UPDATE',
  },
}

export const PASSWORD_SUBMIT_STATE = {
  REFRESHED: 'PASSWORD_SUBMIT_REFRESHED',
  IN_PROGRESS: 'PASSWORD_SUBMIT_PROGRESS',
  SUCCESS: 'PASSWORD_SUBMIT_RETRIEVED',
  ERROR: 'PASSWORD_SUBMIT_ERROR',
  PENDING: 'PASSWORD_SUBMIT_PENDING',
}

export default RESET_PASSWORD_ACTIONS
