const BODYPASS_LIST_ACTIONS = {
  BODYPASS_LIST: {
    PENDING: 'BODYPASS_LIST_PENDING',
    SUCCESS: 'BODYPASS_LIST_SUCCESS',
    FAILURE: 'BODYPASS_LIST_FAILURE',
    REMOVE_LIST: 'BODYPASS_REMOVE_LIST',
  },
}

export const BODYPASS_LIST_STATE = {
  REFRESHED: 'BODYPASS_LIST_REFRESHED',
  IN_PROGRESS: 'BODYPASS_LIST_IN_PROGRESS',
  SUCCESS: 'BODYPASS_LIST_RETRIEVED',
  ERROR: 'BODYPASS_LIST_ERROR',
  PENDING: 'BODYPASS_LIST_PENDING',
}

export default BODYPASS_LIST_ACTIONS
