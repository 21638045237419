const VEHICLEPASS_DATA_ACTIONS = {
  VEHICLEPASS_DATA: {
    PENDING: 'VEHICLEPASS_DATA_PENDING',
    SUCCESS: 'VEHICLEPASS_DATA_SUCCESS',
    FAILURE: 'VEHICLEPASS_DATA_FAILURE',
  },
  VERIFY: {
    PENDING: 'VEHICLEPASS_VERIFY_PENDING',
    SUCCESS: 'VEHICLEPASS_VERIFY_SUCCESS',
    FAILURE: 'VEHICLEPASS_VERIFY_FAILURE',
  },
  REJECT: {
    PENDING: 'VEHICLEPASS_REJECT_PENDING',
    SUCCESS: 'VEHICLEPASS_REJECT_SUCCESS',
    FAILURE: 'VEHICLEPASS_REJECT_FAILURE',
  },
}

export const VEHICLEPASS_DATA_STATE = {
  REFRESHED: 'VEHICLEPASS_DATA_REFRESHED',
  IN_PROGRESS: 'VEHICLEPASS_DATA_IN_PROGRESS',
  SUCCESS: 'VEHICLEPASS_DATA_RETRIEVED',
  ERROR: 'VEHICLEPASS_DATA_ERROR',
  PENDING: 'VEHICLEPASS_DATA_PENDING',
}

export const VEHICLEPASS_VERIFY_STATE = {
  REFRESHED: 'VEHICLEPASS_VERIFY_REFRESHED',
  IN_PROGRESS: 'VEHICLEPASS_VERIFY_IN_PROGRESS',
  SUCCESS: 'VEHICLEPASS_VERIFY_RETRIEVED',
  ERROR: 'VEHICLEPASS_VERIFY_ERROR',
  PENDING: 'VEHICLEPASS_VERIFY_PENDING',
}
export const VEHICLEPASS_REJECT_STATE = {
  REFRESHED: 'VEHICLEPASS_REJECT_REFRESHED',
  IN_PROGRESS: 'VEHICLEPASS_REJECT_IN_PROGRESS',
  SUCCESS: 'VEHICLEPASS_REJECT_RETRIEVED',
  ERROR: 'VEHICLEPASS_REJECT_ERROR',
  PENDING: 'VEHICLEPASS_REJECT_PENDING',
}
export default VEHICLEPASS_DATA_ACTIONS
