const BODYPASS_DATA_ACTIONS = {
  BODYPASS_DATA: {
    PENDING: 'BODYPASS_DATA_PENDING',
    SUCCESS: 'BODYPASS_DATA_SUCCESS',
    FAILURE: 'BODYPASS_DATA_FAILURE',
  },
  VERIFY: {
    PENDING: 'BODYPASS_VERIFY_PENDING',
    SUCCESS: 'BODYPASS_VERIFY_SUCCESS',
    FAILURE: 'BODYPASS_VERIFY_FAILURE',
  },
  REJECT: {
    PENDING: 'BODYPASS_REJECT_PENDING',
    SUCCESS: 'BODYPASS_REJECT_SUCCESS',
    FAILURE: 'BODYPASS_REJECT_FAILURE',
  },
}

export const BODYPASS_DATA_STATE = {
  REFRESHED: 'BODYPASS_DATA_REFRESHED',
  IN_PROGRESS: 'BODYPASS_DATA_IN_PROGRESS',
  SUCCESS: 'BODYPASS_DATA_RETRIEVED',
  ERROR: 'BODYPASS_DATA_ERROR',
  PENDING: 'BODYPASS_DATA_PENDING',
}
export const BODYPASS_VERIFY_STATE = {
  REFRESHED: 'BODYPASS_VERIFY_REFRESHED',
  IN_PROGRESS: 'BODYPASS_VERIFY_IN_PROGRESS',
  SUCCESS: 'BODYPASS_VERIFY_RETRIEVED',
  ERROR: 'BODYPASS_VERIFY_ERROR',
  PENDING: 'BODYPASS_VERIFY_PENDING',
}

export default BODYPASS_DATA_ACTIONS
