const FORGOT_PASSWORD_ACTIONS = {
  EMAIL_SUBMIT: {
    PENDING: 'EMAIL_SUBMIT_PENDING',
    SUCCESS: 'EMAIL_SUBMIT_SUCCESS',
    FAILURE: 'EMAIL_SUBMIT_FAILURE',
    UPDATE: 'EMAIL_SUBMIT_UPDATE',
  },
}

export const EMAIL_SUBMIT_STATE = {
  REFRESHED: 'EMAIL_SUBMIT_REFRESHED',
  IN_PROGRESS: 'EMAIL_SUBMIT_PROGRESS',
  SUCCESS: 'EMAIL_SUBMIT_RETRIEVED',
  ERROR: 'EMAIL_SUBMIT_ERROR',
  PENDING: 'EMAIL_SUBMIT_PENDING',
}

export default FORGOT_PASSWORD_ACTIONS
