const SUMMARY_VEHICLEPASS_ACTIONS = {
  SUMMARY_VEHICLEPASS: {
    PENDING: 'SUMMARY_VEHICLEPASS_PENDING',
    SUCCESS: 'SUMMARY_VEHICLEPASS_SUCCESS',
    FAILURE: 'SUMMARY_VEHICLEPASS_FAILURE',
    UPDATE: 'SUMMARY_VEHICLEPASS_UPDATE',
  },
  FORM_SUBMIT: {
    PENDING: 'FORM_SUBMIT_PENDING',
    SUCCESS: 'FORM_SUBMIT_SUCCESS',
    FAILURE: 'FORM_SUBMIT_FAILURE',
    UPDATE: 'FORM_SUBMIT_UPDATE',
  },
}

export const SUMMARY_VEHICLEPASS_STATE = {
  REFRESHED: 'SUMMARY_VEHICLEPASS_REFRESHED',
  IN_PROGRESS: 'SUMMARY_VEHICLEPASS_PROGRESS',
  SUCCESS: 'SUMMARY_VEHICLEPASS_RETRIEVED',
  ERROR: 'SUMMARY_VEHICLEPASS_ERROR',
  PENDING: 'SUMMARY_VEHICLEPASS_PENDING',
}

export const FORM_SUBMIT_STATE = {
  REFRESHED: 'FORM_SUBMIT_REFRESHED',
  IN_PROGRESS: 'FORM_SUBMIT_PROGRESS',
  SUCCESS: 'FORM_SUBMIT_RETRIEVED',
  ERROR: 'FORM_SUBMIT_ERROR',
  PENDING: 'FORM_SUBMIT_PENDING',
}

export default SUMMARY_VEHICLEPASS_ACTIONS
