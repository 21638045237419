const VEHICLEPASS_TABLE_ACTIONS = {
  VEHICLEPASS_TABLE: {
    PENDING: 'VEHICLEPASS_TABLE_PENDING',
    SUCCESS: 'VEHICLEPASS_TABLE_SUCCESS',
    FAILURE: 'VEHICLEPASS_TABLE_FAILURE',
    REMOVE_LIST: 'VEHICLEPASS_REMOVE_LIST',
  },
}

export const VEHICLEPASS_TABLE_STATE = {
  REFRESHED: 'VEHICLEPASS_TABLE_REFRESHED',
  IN_PROGRESS: 'VEHICLEPASS_TABLE_IN_PROGRESS',
  SUCCESS: 'VEHICLEPASS_TABLE_RETRIEVED',
  ERROR: 'VEHICLEPASS_TABLE_ERROR',
  PENDING: 'VEHICLEPASS_TABLE_PENDING',
}

export default VEHICLEPASS_TABLE_ACTIONS
