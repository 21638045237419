const HISTORY_ACTIONS = {
  HISTORY: {
    PENDING: 'USER_HISTORY_PENDING',
    SUCCESS: 'USER_HISTORY_SUCCESS',
    FAILURE: 'USER_HISTORY_FAILURE',
    REMOVE: 'USER_HISTORY_REMOVE',
  },
  APPLICATION_SUMMARY: {
    PENDING: 'APPLICATION_SUMMARY_PENDING',
    SUCCESS: 'APPLICATION_SUMMARY_SUCCESS',
    FAILURE: 'APPLICATION_SUMMARY_FAILURE',
  },
}

export const HISTORY_STATE = {
  REFRESHED: 'USER_HISTORY_REFRESHED',
  IN_PROGRESS: 'USER_HISTORY_IN_PROGRESS',
  SUCCESS: 'USER_HISTORY_RETRIEVED',
  ERROR: 'USER_HISTORY_ERROR',
  PENDING: 'USER_HISTORY_PENDING',
}

export const APPLICATION_SUMMARY_STATE = {
  REFRESHED: 'APPLICATION_SUMMARY_REFRESHED',
  IN_PROGRESS: 'APPLICATION_SUMMARY_PROGRESS',
  SUCCESS: 'APPLICATION_SUMMARY_RETRIEVED',
  ERROR: 'APPLICATION_SUMMARY_ERROR',
  PENDING: 'APPLICATION_SUMMARY_PENDING',
}

export default HISTORY_ACTIONS
