import ACTION, {
  BRIEFINGINFO_STATE,
  BRIEFINGINFO_SUBMIT_STATE,
  PASS_PRICE_STATE,
  SAFETY_BRIEFING_FORM_SUBMIT_STATE,
  USER_PASS_INFO_STATE,
} from './creator'

const init = {
  status: BRIEFINGINFO_STATE.PENDING,
  message: '',
  briefingInfo: {},
}

const init_submit = {
  status: BRIEFINGINFO_SUBMIT_STATE.PENDING,
  message: '',
}

const init_userPassInfo = {
  status: USER_PASS_INFO_STATE.PENDING,
  message: '',
}

const init_passPrice = {
  status: PASS_PRICE_STATE.PENDING,
  message: '',
}

const init_formSubmit = {
  status: SAFETY_BRIEFING_FORM_SUBMIT_STATE.PENDING,
  message: '',
}
// const init_count = {
//   countWidgetData: {},
//   status: COUNTWIDGET_STATE.PENDING,
//   message: '',
// }

export const safetyBriefingData = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.BRIEFINGINFO.PENDING:
    case ACTION.BRIEFINGINFO.SUCCESS:
      return {
        ...state,
        status: payload.status,
        message: payload.message,
        summaryInfo: {
          fullName: payload.summary.name,
          nationality: payload.summary.nationality,
          icNum: payload.summary.icNum,
          noPhone: payload.summary.noPhone,
          tenantCompany: payload.summary.tenantCompany,
          safetyBriefingDate: payload.summary.safetyBriefingDate,
          safetyBriefingTime: payload.summary.safetyBriefingTime,
        },
      }
    case ACTION.BRIEFINGINFO.FAILURE:
      return { ...state, ...safetyBriefingData }
    default:
      return state
  }
}

export const submitSafetyBriefingData = (state = init_submit, { type, payload }) => {
  switch (type) {
    case ACTION.BRIEFINGINFO_SUBMIT.PENDING:
    case ACTION.BRIEFINGINFO_SUBMIT.SUCCESS:
    case ACTION.BRIEFINGINFO_SUBMIT.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const userPassInfo = (state = init_userPassInfo, { type, payload }) => {
  switch (type) {
    case ACTION.USER_PASS_INFO.PENDING:
    case ACTION.USER_PASS_INFO.SUCCESS:
    case ACTION.USER_PASS_INFO.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const passPrice = (state = init_passPrice, { type, payload }) => {
  switch (type) {
    case ACTION.PASS_PRICE.PENDING:
    case ACTION.PASS_PRICE.SUCCESS:
    case ACTION.PASS_PRICE.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const safetyBriefingFormSubmit = (state = init_formSubmit, { type, payload }) => {
  switch (type) {
    case ACTION.SAFETY_BRIEFING_FORM_SUBMIT.PENDING:
    case ACTION.SAFETY_BRIEFING_FORM_SUBMIT.SUCCESS:
    case ACTION.SAFETY_BRIEFING_FORM_SUBMIT.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
