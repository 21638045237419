const HISTORY_ACTIONS = {
  HISTORY: {
    PENDING: 'TENANT_HISTORY_PENDING',
    SUCCESS: 'TENANT_HISTORY_SUCCESS',
    FAILURE: 'TENANT_HISTORY_FAILURE',
    UPDATE_LIST: 'TENANT_HISTORY_UPDATE_LIST',
  },
}

export const HISTORY_STATE = {
  REFRESHED: 'TENANT_HISTORY_REFRESHED',
  IN_PROGRESS: 'TENANT_HISTORY_IN_PROGRESS',
  SUCCESS: 'TENANT_HISTORY_RETRIEVED',
  ERROR: 'TENANT_HISTORY_ERROR',
  PENDING: 'TENANT_HISTORY_PENDING',
}

export default HISTORY_ACTIONS
