import ACTION, { SAFETYBRIEFING_LIST_STATE } from './creator'

const init = {
  status: SAFETYBRIEFING_LIST_STATE.PENDING,
  message: '',
  tableList: [],
}

export const auxSafetyBriefingTableDataList = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.SAFETYBRIEFING_LIST.PENDING:
    case ACTION.SAFETYBRIEFING_LIST.SUCCESS:
    case ACTION.SAFETYBRIEFING_LIST.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
