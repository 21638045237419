import ACTION, { AUX_CASH_PAYMENT_STATE } from './creator'

const init_cashPayment = {
  status: AUX_CASH_PAYMENT_STATE.PENDING,
  message: '',
  data: {
    list: [],
    pagination: {
      page: 1,
      itemPerPage: 10,
      count: 0,
      totalCount: 0,
    },
  },
}
// const init_count = {
//   countWidgetData: {},
//   status: COUNTWIDGET_STATE.PENDING,
//   message: '',
// }

export const AuxCashPaymentData = (state = init_cashPayment, { type, payload }) => {
  switch (type) {
    case ACTION.AUX_CASH_PAYMENT.PENDING:
    case ACTION.AUX_CASH_PAYMENT.SUCCESS:
      return { ...state, ...payload }
    case ACTION.AUX_CASH_PAYMENT.FAILURE:
      return { ...state, ...payload, data: init_cashPayment.data }
    default:
      return state
  }
}
