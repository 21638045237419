import ACTION, { PROFILE_STATE } from './creator'

const init = {
  profileData: {},
  status: PROFILE_STATE.PENDING,
  message: '',
}

export const profilePhoto = (state = { profilePhoto: '' }, { type, payload }) => {
  switch (type) {
    case ACTION.PHOTO.PENDING:
    case ACTION.PHOTO.SUCCESS:
    case ACTION.PHOTO.FAILURE:
      return payload
    default:
      return state
  }
}

export const profileData = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.PROFILE.PENDING:
    case ACTION.PROFILE.SUCCESS:
    case ACTION.PROFILE.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const changePasswordData = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.CHANGE_PASSWORD.PENDING:
    case ACTION.CHANGE_PASSWORD.SUCCESS:
    case ACTION.CHANGE_PASSWORD.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
