const APPLICATION_APPROVAL_ACTIONS = {
  APPLICATION_APPROVAL: {
    PENDING: 'APPLICATION_APPROVAL_PENDING',
    SUCCESS: 'APPLICATION_APPROVAL_SUCCESS',
    FAILURE: 'APPLICATION_APPROVAL_FAILURE',
  },
}

export const APPLICATION_APPROVAL_STATE = {
  REFRESHED: 'APPLICATION_APPROVAL_REFRESHED',
  IN_PROGRESS: 'APPLICATION_APPROVAL_IN_PROGRESS',
  SUCCESS: 'APPLICATION_APPROVAL_RETRIEVED',
  ERROR: 'APPLICATION_APPROVAL_ERROR',
  PENDING: 'APPLICATION_APPROVAL_PENDING',
}

export default APPLICATION_APPROVAL_ACTIONS
