const TENANTDASHBOARD_DATA_ACTIONS = {
  TENANTDASHBOARD_DATA: {
    PENDING: 'TENANTDASHBOARD_DATA_PENDING',
    SUCCESS: 'TENANTDASHBOARD_DATA_SUCCESS',
    FAILURE: 'TENANTDASHBOARD_DATA_FAILURE',
    UPDATE_LIST: 'TENANTDASHBOARD_UPDATE_LIST',
  },
  PASS_INFO: {
    PENDING: 'TENANT_PASS_INFO_PENDING',
    SUCCESS: 'TENANT_PASS_INFO_SUCCESS',
    FAILURE: 'TENANT_PASS_INFO_FAILURE',
  },
}

export const TENANTDASHBOARD_DATA_STATE = {
  REFRESHED: 'TENANTDASHBOARD_DATA_REFRESHED',
  IN_PROGRESS: 'TENANTDASHBOARD_DATA_IN_PROGRESS',
  SUCCESS: 'TENANTDASHBOARD_DATA_RETRIEVED',
  ERROR: 'TENANTDASHBOARD_DATA_ERROR',
  PENDING: 'TENANTDASHBOARD_DATA_PENDING',
}
export const TENANT_PASS_INFO_DATA_STATE = {
  REFRESHED: 'TENANT_PASS_INFO_DATA_REFRESHED',
  IN_PROGRESS: 'TENANT_PASS_INFO_DATA_IN_PROGRESS',
  SUCCESS: 'TENANT_PASS_INFO_DATA_RETRIEVED',
  ERROR: 'TENANT_PASS_INFO_DATA_ERROR',
  PENDING: 'TENANT_PASS_INFO_DATA_PENDING',
}

export default TENANTDASHBOARD_DATA_ACTIONS
