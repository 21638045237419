const HISTORY_ACTIONS = {
  HISTORY: {
    PENDING: 'HISTORY_PENDING',
    SUCCESS: 'HISTORY_SUCCESS',
    FAILURE: 'HISTORY_FAILURE',
    UPDATE_LIST: 'HISTORY_UPDATE_LIST',
  },
}

export const HISTORY_STATE = {
  REFRESHED: 'HISTORY_REFRESHED',
  IN_PROGRESS: 'HISTORY_IN_PROGRESS',
  SUCCESS: 'HISTORY_RETRIEVED',
  ERROR: 'HISTORY_ERROR',
  PENDING: 'HISTORY_PENDING',
}

export default HISTORY_ACTIONS
