import ACTION, { SAFETYSECURITY_LIST_STATE, SAFETYSECURITY_LIST_PENDING_STATE } from './creator'

const init = {
  status: SAFETYSECURITY_LIST_STATE.PENDING,
  message: '',
  tableList: [],
}
const init_bp_pending = {
  status: SAFETYSECURITY_LIST_PENDING_STATE.PENDING,
  message: '',
  tableList: [],
}

export const AuxSafetySecurityTableByTenant = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.SAFETYSECURITY_LIST.PENDING:
    case ACTION.SAFETYSECURITY_LIST.SUCCESS:
    case ACTION.SAFETYSECURITY_LIST.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
export const AuxSafetySecurityPendingTableByTenant = (
  state = init_bp_pending,
  { type, payload },
) => {
  switch (type) {
    case ACTION.LIST_PENDING.PENDING:
    case ACTION.LIST_PENDING.SUCCESS:
    case ACTION.LIST_PENDING.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
