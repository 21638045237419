import ACTION, {
  STATUS_DATA_STATE,
  USERDASHBOARD_DATA_STATE,
  PASS_INFO_DATA_STATE,
} from './creator'

const init = {
  status: USERDASHBOARD_DATA_STATE.PENDING,
  message: '',
  tableList: [],
}

const pass_info_data_init = {
  status: PASS_INFO_DATA_STATE.PENDING,
  message: '',
  data: {
    bodyPass: {
      active: 0,
      expired: 0,
      pending: 0,
    },
    vehiclePass: {
      active: 0,
      expired: 0,
      pending: 0,
    },
    safetyBriefing: {
      status: 'Not Found',
      expiredDate: '',
    },
  },
}

const status_data_init = {
  status: STATUS_DATA_STATE.PENDING,
  message: '',
  data: [],
}

export const userDashboardDataList = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.USERDASHBOARD_DATA.PENDING:
    case ACTION.USERDASHBOARD_DATA.SUCCESS:
    case ACTION.USERDASHBOARD_DATA.FAILURE:
      return { ...state, ...payload }
    case ACTION.USERDASHBOARD_DATA.REMOVE:
      if (!payload.id) {
        return
      }

      let list = []
      const findIndex = state.tableList.findIndex((item) => {
        return item.id === payload.id
      })
      if (findIndex !== -1) {
        if (findIndex === 0) {
          list = state.tableList.slice(1)
        } else if (findIndex === state.tableList.length - 1) {
          list = state.tableList.slice(0, findIndex)
        } else {
          const rest = state.tableList.splice(findIndex + 1)
          state.tableList.splice(findIndex)
          list = state.tableList.concat(rest)
        }
        list = list.map((value, index) => ({ ...value, no: index + 1 }))
      }

      return { status: state.status, message: state.status, tableList: list }
    default:
      return state
  }
}

export const passInfoData = (state = pass_info_data_init, { type, payload }) => {
  switch (type) {
    case ACTION.PASS_INFO.PENDING:
    case ACTION.PASS_INFO.SUCCESS:
      return { ...state, ...payload }
    case ACTION.PASS_INFO.FAILURE:
      return { ...state, ...payload, data: pass_info_data_init.data }
    default:
      return state
  }
}

export const statusData = (state = status_data_init, { type, payload }) => {
  switch (type) {
    case ACTION.STATUS_DATA.PENDING:
    case ACTION.STATUS_DATA.SUCCESS:
    case ACTION.STATUS_DATA.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
